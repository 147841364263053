import * as PIXI from 'pixi.js';
import { IPointData } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setSlotMachineInitialized,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage, easeInOutQuart, isFreeSpinsMode, isMobileDevice } from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { GameViewLayout, SAFE_AREA_PORTRAIT_PIVOT_Y, eventManager } from '../config';
import AutoResizeText from '../text/autoResizeText';

import {
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
} from './config';
import {
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
} from './textStyles';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  private isDisabled: boolean;

  private isLeftHandMode: boolean;

  private window: PIXI.ISize = { width: 0, height: 0 };

  private applicationSize: { width: number; height: number };

  private gameContainerHeight: number;

  private underGameContainerSpaceHeight: number;

  private isMobile: boolean;

  private gameContainerScale: number;

  private safeAreaPos: IPointData;

  private isFreeRoundBonus: boolean;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.isLeftHandMode = setIsLeftHandMode();
    this.isFreeRoundBonus = false;

    this.btn = this.initBuyFeatureBtn();
    this.text = this.initBuyFeatureText();

    this.applicationSize = { width: 0, height: 0 };
    this.gameContainerHeight = 0;
    this.underGameContainerSpaceHeight = 0;
    this.gameContainerScale = 1;
    this.safeAreaPos = { x: 0, y: 0 };

    this.isMobile = isMobileDevice();

    this.addChild(this.btn, this.text);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.CHANGE_MODE, ({ mode }) => {
      this.visible = !isFreeSpinsMode(mode);
      if (!isFreeSpinsMode(mode)) {
        this.setObjAlpha(1.0);
      }
    });
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, ({ mode }) => {
      this.visible = !isFreeSpinsMode(mode);
      if (!isFreeSpinsMode(mode)) {
        this.setObjAlpha(1.0);
      }
    });
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });

    eventManager.addListener(EventTypes.PACHIDROP_ZOOM_START, this.startZoomAnimation.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_END, this.onPachiDropEnd.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_SET_WHEEL_VIEW, this.setPachiDropView.bind(this));

    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyBotton));
    btn.anchor.set(0.5);
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyBottonOver);

        this.text.style = buyFeatureHoverTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyBotton);

        this.text.style = buyFeatureTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyBottonClick);

        this.text.style = buyFeatureClickTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyBotton);

        this.text.style = buyFeatureTextStyle;
        this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
      }
    });
    return btn;
  }

  private initBuyFeatureText(): AutoResizeText {
    const text = new AutoResizeText('BUY\nFREE\nSPINS', buyFeatureTextStyle);

    text.anchor.set(0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);

    return text;
  }

  private onClick(): void {
    if (
      !this.isDisabled &&
      setIsModalOpeningInProgress() === ModalOpeningTypes.NONE &&
      !setIsAutoSpins() &&
      setSlotMachineInitialized()
    ) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioApi.play({ type: ISongs.XT002S_PushBuyFeature });
      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private setObjAlpha(alpha: number) {
    this.btn.alpha = alpha;
    this.text.alpha = alpha;
  }

  private makeZoomAnimation(targetObj: PIXI.Sprite | PIXI.Text, tAlpha: number, duration: number): AnimationGroup {
    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(
      new Tween({
        object: targetObj,
        property: TweenProperties.ALPHA,
        propertyBeginValue: targetObj.alpha,
        target: tAlpha,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );

    return animationGroup;
  }

  private startZoomAnimation(isStart: boolean) {
    if (isStart) {
      this.makeZoomAnimation(this.text, 0, 1000).start();
      this.makeZoomAnimation(this.btn, 0, 1000).start();
    } else {
      this.makeZoomAnimation(this.text, 1.0, 1000).start();
      this.makeZoomAnimation(this.btn, 1.0, 1000).start();
    }
  }

  private onPachiDropEnd() {
    this.setObjAlpha(1.0);
  }

  private setPachiDropView() {
    this.setObjAlpha(0);
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyBottonDisable);

      this.text.style = buyFeatureDisableTextStyle;
      this.text.position.set(FEATURE_BTN_TEXT_PRESS_X_OFFSET, FEATURE_BTN_TEXT_PRESS_Y_OFFSET);
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyBotton);

      this.text.style = buyFeatureTextStyle;
      this.text.position.set(FEATURE_BTN_TEXT_X_OFFSET, FEATURE_BTN_TEXT_Y_OFFSET);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private applicationResize = (width: number, height: number): void => {
    const isPortraitMode = width < height && this.isMobile;
    this.applicationSize.width = width;
    this.applicationSize.height = isPortraitMode ? calcPercentage(height, 92.5) : calcPercentage(height, 95);
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, height: number, x: number, y: number, scale: number): void => {
    const gameContainerBottomPosition = y + height;
    this.gameContainerHeight = gameContainerBottomPosition;
    this.underGameContainerSpaceHeight = this.applicationSize.height - gameContainerBottomPosition;
    this.gameContainerScale = scale;
    this.safeAreaPos = { x: x, y: y };
    this.handlePosition();
  };

  private handlePosition(): void {
    if (this.window.width >= this.window.height) {
      this.pivot.x = this.isLeftHandMode ? -this.btn.width / 2 : this.btn.width / 2;
      this.pivot.y = 0;
      this.x = this.isLeftHandMode ? GameViewLayout.ReelBase.width + 30 : -45;
      this.y = GameViewLayout.ReelBase.height / 2 + 30;
      this.scale.set(1);
    } else {
      this.pivot.x = this.isLeftHandMode ? this.btn.width / 2 : -this.btn.width / 2;
      this.x = this.isLeftHandMode
        ? GameViewLayout.ReelBase.width + this.safeAreaPos.x / this.gameContainerScale
        : 30 - this.safeAreaPos.x / this.gameContainerScale;
      this.y =
        (this.gameContainerHeight - this.safeAreaPos.y) / this.gameContainerScale +
        SAFE_AREA_PORTRAIT_PIVOT_Y +
        calcPercentage(this.underGameContainerSpaceHeight, 36) / this.gameContainerScale;
      this.scale.set(calcPercentage(this.applicationSize.height, 9) / this.gameContainerScale / this.btn.height);
    }
  }
}

export default BuyFeatureBtn;
