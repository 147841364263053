import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import { bottomContainerTextStyle, eventManager } from '../config';

class GameReplay extends PIXI.Container {
  private replay = new PIXI.Container();

  constructor() {
    super();

    const replayText = new PIXI.Text(i18n.t<string>('replay'), {
      ...bottomContainerTextStyle,
      fill: 'red',
      fontSize: 40,
    });
    replayText.x = 80;
    this.replay.addChild(replayText);
    const circle = new PIXI.Graphics();
    circle.beginFill(0xff0000);
    circle.drawCircle(25, 25, 12.5);
    circle.endFill();
    circle.x = 40;
    this.replay.addChild(circle);
    this.replay.visible = false;
    this.addChild(this.replay);
    eventManager.on(EventTypes.SET_REPLAY_BET_ID, () => {
      this.replay.visible = true;
    });
    eventManager.on(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.on(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
  }

  private window: PIXI.ISize = { width: 0, height: 0 };

  private resize(width: number, height: number): void {
    this.window = { width, height };
  }

  private gameContainerResize = (_width: number, _height: number, x: number, y: number): void => {
    queueMicrotask(() => {
      if (this.window.height > this.window.width) {
        this.parent.toLocal({ x: x, y: y / 2 }, undefined, this.position);
        this.position.y += 505;
        this.position.x += 52;
      } else {
        this.parent.toLocal({ x: x, y: y }, undefined, this.position);
        this.position.y += 380;
      }
    });
  };
}

export default GameReplay;
