import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import ViewContainer from '../../components/container';
import { GameViewLayout } from '../../config';
import { PACHIDROP_PRIZE_SPINE, PachiDropPrizeType } from '../config';

class PrizeBgContainer extends ViewContainer {
  public prizes: Spine[] = [];

  constructor(prizeLayout: PachiDropPrizeType[]) {
    super();

    prizeLayout.forEach((type, index) => {
      const pr = new Spine(PIXI.Loader.shared.resources[PACHIDROP_PRIZE_SPINE[type]!.spineName]!.spineData!);
      pr.position.x = (GameViewLayout.PachiDrop.Prize.p2.left - GameViewLayout.PachiDrop.Prize.p1.left) * index;
      pr.pivot.set(-GameViewLayout.PachiDrop.Prize.p2.width / 2, -GameViewLayout.PachiDrop.Prize.p2.height / 2);

      if (type === 'lose' || type === 'v_bs' || type === 'v_fs') {
        pr.visible = false;
      } else {
        pr.state.setAnimation(0, PACHIDROP_PRIZE_SPINE[type]!.bgAnim!);
      }

      this.prizes.push(pr);
      this.addChild(pr);
    });
  }
}

export default PrizeBgContainer;
