import { IAddOptions, LoaderResource } from 'pixi.js';
import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.S]: ResourceTypes.symbolD,
  [SlotId.G]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolSc,
  [SlotId.X]: ResourceTypes.symbolBl,
};

export const MAPPED_SPINE_SYMBOLS: Record<SlotId, string> = {
  [SlotId.A]: 'A',
  [SlotId.B]: 'B',
  [SlotId.C]: 'C',
  [SlotId.S]: 'D',
  [SlotId.G]: 'E',
  [SlotId.F]: 'SC',
  [SlotId.X]: 'BL',
};

export const MAPPED_BLURRED_SYMBOLS: Record<SlotId, ResourceTypes> = {
  [SlotId.A]: ResourceTypes.blurSymbolA,
  [SlotId.B]: ResourceTypes.blurSymbolB,
  [SlotId.C]: ResourceTypes.blurSymbolC,
  [SlotId.S]: ResourceTypes.blurSymbolD,
  [SlotId.G]: ResourceTypes.blurSymbolE,
  [SlotId.F]: ResourceTypes.blurSymbolSc,
  [SlotId.X]: ResourceTypes.blurSymbolBl,
};

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.symbolA,
  ResourceTypes.symbolB,
  ResourceTypes.symbolC,
  ResourceTypes.symbolD,
  ResourceTypes.symbolE,
  ResourceTypes.symbolSc,
  ResourceTypes.symbolBl,
  ResourceTypes.buttonOk,
  ResourceTypes.blurSymbolA,
  ResourceTypes.blurSymbolB,
  ResourceTypes.blurSymbolC,
  ResourceTypes.blurSymbolD,
  ResourceTypes.blurSymbolE,
  ResourceTypes.blurSymbolSc,
  ResourceTypes.blurSymbolBl,
  ResourceTypes.largeLine,
  ResourceTypes.buyBotton,
  ResourceTypes.buyBottonClick,
  ResourceTypes.buyBottonDisable,
  ResourceTypes.buyBottonOver,
  ResourceTypes.bannerFsBuy1,
  ResourceTypes.bannerFsBuy1A,
  ResourceTypes.bannerFsTriger,
  ResourceTypes.buyFeatureBtnCancel,
  ResourceTypes.buyFeatureBtnCancelClick,
  ResourceTypes.buyFeatureBtnCancelOver,
  ResourceTypes.buyFeatureBtnM,
  ResourceTypes.buyFeatureBtnMClick,
  ResourceTypes.buyFeatureBtnMDisable,
  ResourceTypes.buyFeatureBtnMOver,
  ResourceTypes.buyFeatureBtnOk,
  ResourceTypes.buyFeatureBtnOkClick,
  ResourceTypes.buyFeatureBtnOkDisable,
  ResourceTypes.buyFeatureBtnOkOver,
  ResourceTypes.buyFeatureBtnP,
  ResourceTypes.buyFeatureBtnPClick,
  ResourceTypes.buyFeatureBtnPDisable,
  ResourceTypes.buyFeatureBtnPOver,
  ResourceTypes.reelFrameMask,
  ResourceTypes.infoSymbolCoin,
  ResourceTypes.messageBigwin,
  ResourceTypes.messageEpicwin,
  ResourceTypes.messageGreatwin,
  ResourceTypes.messageMegawin,
  ResourceTypes.messageWin,
  ResourceTypes.bgBottom,
  ResourceTypes.bgBottomHuti,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const SPINE_ATLASES: IAddOptions[] = [
  {
    name: 'spine_atlas',
    url: '/animations/desktop/spine/merge.atlas',
    loadType: LoaderResource.LOAD_TYPE.XHR,
    xhrType: LoaderResource.XHR_RESPONSE_TYPE.TEXT,
  },
];

export const generateTexturePath = (before: string, after: string, isMobile = false): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};
export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'coinsAnimation',
      url: generateTexturePath('/animations', 'coins/coins.json', isMobile),
    },
    {
      name: 'BG_Basegame',
      url: generateTexturePath('/animations', 'spine/BG_Basegame.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Jackpot_Frame',
      url: generateTexturePath('/animations', 'spine/Jackpot_Frame.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Pachi_Hit',
      url: generateTexturePath('/animations', 'spine/Pachi_Hit.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Pachi_Hit_V',
      url: generateTexturePath('/animations', 'spine/Pachi_Hit_V.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Pachi_Borad',
      url: generateTexturePath('/animations', 'spine/Pachi_Board.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Title_Logo',
      url: generateTexturePath('/animations', 'spine/Title_Logo.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },

    {
      name: 'ReelFrame_Back',
      url: generateTexturePath('/animations', 'spine/ReelFrame_back.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'ReelFrame_Front',
      url: generateTexturePath('/animations', 'spine/ReelFrame_front.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },

    {
      name: 'Opening',
      url: generateTexturePath('/animations', 'spine/Opening.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'OpeningBg',
      url: generateTexturePath('/animations', 'spine/Opening_bg.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'DropCoin',
      url: generateTexturePath('/animations', 'spine/Pachi_Board_dropCoin.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Wheel',
      url: generateTexturePath('/animations', 'spine/Wheel.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'symbol',
      url: generateTexturePath('/animations', 'spine/symbol.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'IntroScreen',
      url: generateTexturePath('/animations', 'spine/IntroScreen.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'WheelNusyoEff',
      url: generateTexturePath('/animations', 'spine/Wheel_nusyo_eff.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'ReelFrame_alpaca',
      url: generateTexturePath('/animations', 'spine/ReelFrame_alpaca.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Wheel_Spin_btn',
      url: generateTexturePath('/animations', 'spine/Wheel_Spin_btn.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Message',
      url: generateTexturePath('/animations', 'spine/Message.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'BG_Freespins',
      url: generateTexturePath('/animations', 'spine/BG_Freespins.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'Wheel_count_icon',
      url: generateTexturePath('/animations', 'spine/Wheel_count_icon.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
    {
      name: 'BG_Basegame_front',
      url: generateTexturePath('/animations', 'spine/BG_Basegame_front.json'),
      metadata: {
        atlasRawData: PIXI.Loader.shared.resources['spine_atlas']!.data as string,
        imageNamePrefix: 'spine_',
      },
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
];

export const PRELOAD_SPINE_TEXTURES: IAddOptions[] = [
  'no_alpha-0.jpg',
  'no_alpha-1.jpg',
  'no_alpha-2.jpg',
  'alpha-0.png',
  'alpha-1.png',
  'bg-0.png',
  'gray-0.png',
].map((v) => {
  return { name: `spine_${v}`, url: `/animations/desktop/spine/${v}` };
});
