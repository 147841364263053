import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { calcPercentage, easeInOutQuart, isFreeSpinsMode } from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { eventManager } from '../config';

type BgState = 'base' | 'toPachiDrop' | 'pachidrop' | 'freespin';

type BgFrontPart = 'bgA' | 'bgB' | 'bgC' | 'bgD';

const BG_FRONT_ZOOM_SCALE = 1.2;
class BackgroundFront extends PIXI.Container {
  private bgPart: Record<BgFrontPart, Spine>;

  private applicationSize: { width: number; height: number };

  private isPortraitMode: boolean;

  private gameContainerScale: number;

  private state: BgState;

  private posBase: Record<BgFrontPart, PIXI.IPointData>;

  private posTarget: Record<BgFrontPart, PIXI.IPointData>;

  constructor() {
    super();

    this.state = 'base';

    this.posBase = {
      bgA: { x: 0, y: 0 },
      bgB: { x: 0, y: 0 },
      bgC: { x: 0, y: 0 },
      bgD: { x: 0, y: 0 },
    };

    this.posTarget = {
      bgA: { x: 0, y: 0 },
      bgB: { x: 0, y: 0 },
      bgC: { x: 0, y: 0 },
      bgD: { x: 0, y: 0 },
    };

    this.bgPart = {
      bgA: new Spine(PIXI.Loader.shared.resources['BG_Basegame_front']!.spineData!),
      bgB: new Spine(PIXI.Loader.shared.resources['BG_Basegame_front']!.spineData!),
      bgC: new Spine(PIXI.Loader.shared.resources['BG_Basegame_front']!.spineData!),
      bgD: new Spine(PIXI.Loader.shared.resources['BG_Basegame_front']!.spineData!),
    };
    this.bgPart.bgA.state.setAnimation(0, 'A', true);
    this.bgPart.bgB.state.setAnimation(0, 'B', true);
    this.bgPart.bgC.state.setAnimation(0, 'C', true);
    this.bgPart.bgD.state.setAnimation(0, 'D', true);

    this.addChild(this.bgPart.bgA, this.bgPart.bgB, this.bgPart.bgC, this.bgPart.bgD);

    this.applicationSize = { width: 0, height: 0 };
    this.isPortraitMode = false;

    this.gameContainerScale = 1;

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_ZOOM_START, this.startZoomAnimation.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_END, this.onPachiDropEnd.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_WHEEL_VIEW, this.setPachiDropView.bind(this));
  }

  private onChangeMode(settings: { mode: GameMode }) {
    const { mode } = settings;

    if (isFreeSpinsMode(mode)) {
      this.visible = false;
      this.setPachiDropView();
      this.state = 'freespin';
    } else {
      this.visible = this.isPortraitMode ? false : true;
      this.onPachiDropEnd();
      this.state = 'base';
    }
  }

  private makeZoomAnimation(
    targetObj: Spine,
    sourcePos: PIXI.IPointData,
    targetPos: PIXI.IPointData,
    tScale: number,
    tAlpha: number,
    duration: number,
  ): AnimationGroup {
    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(
      new Tween({
        object: targetObj.position,
        property: TweenProperties.X,
        propertyBeginValue: sourcePos.x,
        target: targetPos.x,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: targetObj.position,
        property: TweenProperties.Y,
        propertyBeginValue: sourcePos.y,
        target: targetPos.y,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: targetObj.scale,
        property: TweenProperties.X,
        propertyBeginValue: targetObj.scale.x,
        target: tScale,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: targetObj.scale,
        property: TweenProperties.Y,
        propertyBeginValue: targetObj.scale.x,
        target: tScale,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: targetObj,
        property: TweenProperties.ALPHA,
        propertyBeginValue: targetObj.alpha,
        target: tAlpha,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );

    return animationGroup;
  }

  private startZoomAnimation(isStart: boolean) {
    if (isStart) {
      Object.keys(this.bgPart).forEach((key) => {
        const bgKey = key as BgFrontPart;
        const bg = this.bgPart[bgKey];
        this.makeZoomAnimation(
          bg,
          this.posBase[bgKey],
          this.posTarget[bgKey],
          this.gameContainerScale * BG_FRONT_ZOOM_SCALE,
          0,
          1000,
        ).start();
      });
      this.state = 'toPachiDrop';
      const delay = Tween.createDelayAnimation(1000);
      delay.onComplete = () => {
        this.state = 'pachidrop';
      };
      delay.start();
    } else {
      Object.keys(this.bgPart).forEach((key) => {
        const bgKey = key as BgFrontPart;
        const bg = this.bgPart[bgKey];

        this.makeZoomAnimation(
          bg,
          this.posTarget[bgKey],
          this.posBase[bgKey],
          this.gameContainerScale * 1.0,
          1.0,
          1000,
        ).start();
      });
      this.state = 'toPachiDrop';
      const delay = Tween.createDelayAnimation(1000);
      delay.onComplete = () => {
        this.state = 'base';
      };
      delay.start();
    }
  }

  private onPachiDropEnd() {
    this.state = 'base';

    Object.keys(this.bgPart).forEach((key) => {
      const bgKey = key as BgFrontPart;
      const bg = this.bgPart[bgKey];

      bg.x = this.posBase[bgKey]!.x;
      bg.y = this.posBase[bgKey]!.y;
      bg.scale.set(this.gameContainerScale * 1);
      bg.alpha = 1.0;
    });
  }

  private setPachiDropView() {
    this.state = 'pachidrop';
    Object.keys(this.bgPart).forEach((key) => {
      const bgKey = key as BgFrontPart;
      const bg = this.bgPart[bgKey];
      bg.x = this.posTarget[bgKey]!.x;
      bg.y = this.posTarget[bgKey]!.y;
      bg.scale.set(this.gameContainerScale * BG_FRONT_ZOOM_SCALE);
      bg.alpha = 0;
    });
  }

  private applicationResize = (width: number, height: number): void => {
    this.isPortraitMode = width < height;
    this.applicationSize.width = width;
    this.applicationSize.height = this.isPortraitMode ? calcPercentage(height, 75) : calcPercentage(height, 100);
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, scale: number): void => {
    if (this.isPortraitMode) {
      this.visible = false;
    } else {
      this.visible = true;
    }

    const baseData: Record<BgFrontPart, PIXI.IPointData> = {
      bgA: { x: 0, y: 0 },
      bgB: { x: 0, y: 100 },
      bgC: { x: 100, y: 0 },
      bgD: { x: 100, y: 100 },
    };
    const targetData: Record<BgFrontPart, PIXI.IPointData> = {
      bgA: { x: -10, y: -10 },
      bgB: { x: -10, y: 110 },
      bgC: { x: 110, y: -10 },
      bgD: { x: 110, y: 110 },
    };

    this.gameContainerScale = scale;

    Object.keys(this.bgPart).forEach((key) => {
      const bgKey = key as BgFrontPart;
      const bg = this.bgPart[bgKey];

      this.posBase[bgKey]!.x = calcPercentage(this.applicationSize.width, baseData[bgKey]!.x);
      this.posBase[bgKey]!.y = calcPercentage(this.applicationSize.height, baseData[bgKey]!.y);
      this.posTarget[bgKey]!.x = calcPercentage(this.applicationSize.width, targetData[bgKey]!.x);
      this.posTarget[bgKey]!.y = calcPercentage(this.applicationSize.height, targetData[bgKey]!.y);

      const position = this.state === 'base' ? this.posBase[bgKey] : this.posTarget[bgKey];

      const setScale = this.state === 'base' ? this.gameContainerScale : this.gameContainerScale * BG_FRONT_ZOOM_SCALE;

      bg.position.set(position.x, position.y);
      bg.scale.set(setScale);
    });
  };
}

export default BackgroundFront;
