import * as PIXI from 'pixi.js';
import { TilingSprite } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { calcPercentage, isMobileDevice } from '../../utils';
import { GameViewLayout, SAFE_AREA_PORTRAIT_PIVOT_Y, eventManager } from '../config';

class BgWall extends PIXI.Container {
  private wall: TilingSprite;

  private huchi: TilingSprite;

  private applicationSize: { width: number; height: number };

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.wall = new TilingSprite(PIXI.Texture.from(ResourceTypes.bgBottom));
    this.huchi = new TilingSprite(PIXI.Texture.from(ResourceTypes.bgBottomHuti));

    this.addChild(this.wall, this.huchi);

    this.applicationSize = { width: 0, height: 0 };
    this.window = { width: 0, height: 0 };

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private setTile(x: number, y: number, width: number, height: number) {
    this.huchi.width = width;
    this.huchi.height = 40;
    this.huchi.x = x;
    this.huchi.y = y;

    this.wall.width = width;
    this.wall.height = height;
    this.wall.x = x;
    this.wall.y = y + 15;
  }

  private applicationResize = (width: number, height: number): void => {
    const isPortraitMode = width < height && isMobileDevice();
    this.applicationSize.width = width;
    this.applicationSize.height = isPortraitMode ? calcPercentage(height, 92.5) : calcPercentage(height, 95);
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, height: number, _x: number, y: number, scale: number): void => {
    const wallY =
      this.window.height > this.window.width
        ? height / scale +
          SAFE_AREA_PORTRAIT_PIVOT_Y +
          calcPercentage(this.applicationSize.height - y - height, 18) / scale
        : GameViewLayout.ReelBase.height + calcPercentage(height, 45) / scale;

    this.setTile(
      GameViewLayout.ReelBase.width / 2 - (this.window.width / scale / 2) * 1.1,
      wallY,
      (this.window.width / scale) * 1.1,
      this.window.height / scale / 2,
    );
  };
}

export default BgWall;
