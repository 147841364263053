import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotMachine from '../../slotMachine';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoFeature' });

  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');

  useEffect(() => {
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins[
      'extract'
    ] as PIXI.Extract;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().buyFeatureBtn!));
  }, []);

  const featuresPachiDrop: string[] = t('PachiDropDesc', {
    returnObjects: true,
  });

  const featuresWheel: string[] = t('SunGodWheelDesc', {
    returnObjects: true,
  });

  const featuresFreeSpins: string[] = t('FreeSpinDesc', {
    returnObjects: true,
  });

  const featuresBuyAFeature: string[] = t('BuyAFeatureDesc', {
    returnObjects: true,
  });

  return (
    <section className={styles['feature']!}>
      <h1 className={styles['title']!}>{t('PachiDropTitle')}</h1>
      <div className={`${styles['p']!} ${styles['left']!}`}>
        {featuresPachiDrop.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles['title']!}>{t('SunGodWheelTitle')}</h1>
      <div className={`${styles['p']!} ${styles['left']!}`}>
        {featuresWheel.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles['title']!}>{t('FreeSpinTitle')}</h1>
      <div className={`${styles['p']!} ${styles['left']!}`}>
        {featuresFreeSpins.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
      <h1 className={styles['title']!}>{t('BuyAFeatureTitle')}</h1>
      <div className={`${styles['featureIcon']!}`}>
        <img className="buyFeatureBtn" draggable="false" src={infoBuyFeatureIcon} />
      </div>
      <div className={`${styles['p']!} ${styles['left']!}`}>
        {featuresBuyAFeature.map((v, i) => (
          <div key={`${i}`} dangerouslySetInnerHTML={{ __html: v }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']!}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
