import React from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { wrap } from '../../utils';

import Settings from './index';
import styles from './settings.module.scss';

export const SettingIcon: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
}> = ({ setType }) => (
  <div className={styles['settings-menu']}>
    <Button
      intent="settings"
      onClick={() => {
        setType('settings');
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
      }}
    />
  </div>
);

const SettingsMenu: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ setType, show }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['wrap']} style={{ display: show ? 'flex' : 'none' }}>
      <div className="popup__title">{t('settings')}</div>
      <Settings handleClose={wrap(setType, 'menu')} />
    </div>
  );
};

export default SettingsMenu;
