export type MoneyWheelSilverType = 5 | 10 | 15 | 20 | 30 | 50 | 100 | 500 | 1000 | 3000;

export type MoneyWheelGoldType = 20 | 30 | 50 | 70 | 100 | 200 | 300 | 500 | 1000 | 3000;

export type MoneyWheelType = 'Silver' | 'Gold';

export type MoneyWheelDetailType =
  | 'Silver1'
  | 'Silver2'
  | 'Silver3'
  | 'Silver4'
  | 'Gold1'
  | 'Gold2'
  | 'Gold3'
  | 'Gold4';

export const moneyWheelNumToType: Record<MoneyWheelType, MoneyWheelDetailType[]> = {
  Silver: ['Silver1', 'Silver2', 'Silver3', 'Silver4'],
  Gold: ['Gold1', 'Gold2', 'Gold3', 'Gold4'],
};

export const MONEYWHEEL_ITEM_NUM = 15;

export type MoneyWheelActLotType = 'a' | 'b' | 'c';

export const moneyWheelActConv: MoneyWheelActLotType[] = [
  'b',
  'a',
  'c',
  'a',
  'b',
  'a',
  'b',
  'c',
  'a',
  'b',
  'c',
  'a',
  'c',
  'a',
  'b',
  'b',
  'a',
  'c',
  'a',
  'b',
  'a',
  'b',
  'c',
  'a',
  'b',
  'c',
  'a',
  'c',
  'a',
  'b',
  'b',
  'a',
  'c',
  'a',
  'b',
  'a',
  'b',
  'c',
  'a',
  'b',
  'c',
  'a',
  'c',
  'a',
  'b',
  'b',
  'a',
  'c',
  'a',
  'b',
  'a',
  'b',
  'c',
  'a',
  'b',
  'c',
  'a',
  'c',
  'a',
  'b',
];

export type MoneyWheelSkin =
  | 'default'
  | 'silver_01'
  | 'silver_02'
  | 'silver_03'
  | 'silver_04'
  | 'gold_01'
  | 'gold_02'
  | 'gold_03'
  | 'gold_04';

export const MomeyWheelTypeToSkin: Record<MoneyWheelDetailType, MoneyWheelSkin> = {
  Silver1: 'silver_01',
  Silver2: 'silver_02',
  Silver3: 'silver_03',
  Silver4: 'silver_04',
  Gold1: 'gold_01',
  Gold2: 'gold_02',
  Gold3: 'gold_03',
  Gold4: 'gold_04',
};

export const moneyWheelRotTypeLot = [1, 1, 1];
export const moneyWheelRotType = ['rot_A', 'rot_B', 'rot_C'];

export const moneyWheelActTypeLot: Record<MoneyWheelActLotType, number[]> = {
  a: [0, 5, 5],
  b: [1, 8, 1],
  c: [1, 1, 8],
};
