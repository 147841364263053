import _ from 'lodash';

import { ISongs, audioSprite } from './sprite.generated';

export * from './sprite.generated';

export const audioSpriteVolume: Partial<Record<ISongs, number>> = {};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1],
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
