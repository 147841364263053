import { AutoResizeTextStyle } from '../text/autoResizeText';

const font = 'NotoSans-SemiCondensedBold';

export const titleTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 84,
  fontFamily: font,
  fontWeight: 'bold',
  align: 'center',
  lineJoin: 'round',
  fill: '#ff8e00',
  maxWidth: 1000,
};

export const youWonTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 64,
  fontFamily: font,
  fontWeight: 'bold',
  lineJoin: 'round',
  fill: '#fff',
  maxWidth: 1024,
};

export const featureNameTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 72,
  fontFamily: font,
  fontWeight: 'bold',
  lineJoin: 'round',
  fill: '#ffd200',
  maxWidth: 1024,
};

export const descriptionTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 64,
  fontFamily: font,
  fontWeight: 'bold',
  fill: '0xffffff',
  lineJoin: 'round',
  maxWidth: 1024,
};

export const btnTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 45.5,
  fontFamily: font,
  fontWeight: 'bold',
  fill: '#fff',
  lineJoin: 'round',
  maxWidth: 1000,
};

export const totalWinTitleStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  fontSize: 60,
  fill: '#ffffff',
};

export const totalWinAmountTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  fontWeight: 'bold',
  fill: ['#ffa200', '#ffc524', '#fdff85', '#ff931f', '#ffe438', '#eb880c'],
  fillGradientStops: [0.4],
  fontSize: 132,
  lineJoin: 'round',
  stroke: '#ffffff',
  strokeThickness: 4,
  maxWidth: 1024,
};

export const freeSpinWinBannerBtnTextStyles: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  fill: 0xffffff,
  fontSize: 48,
  lineJoin: 'round',
  strokeThickness: 4,
  maxWidth: 1024,
};
