import { Spine, TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { eventManager } from '../../config';
import { IPachiDropPrizeSpine, PACHIDROP_PRIZE_SPINE, PachiDropCoinType, PachiDropPrizeType } from '../config';

import { IPachiDropPrizeBase } from './pachiDropPrizeBase';

class PachiDropPrize extends PIXI.Container implements IPachiDropPrizeBase {
  private spine: Spine;

  public type: PachiDropPrizeType;

  private spineInfo: IPachiDropPrizeSpine;

  constructor(type: PachiDropPrizeType) {
    super();
    this.type = type;
    this.spineInfo = PACHIDROP_PRIZE_SPINE[type];
    this.spine = new Spine(PIXI.Loader.shared.resources[PACHIDROP_PRIZE_SPINE[type]!.spineName]!.spineData!);
    this.setSkin(this.spineInfo.skinName);
    this.spine.state.setAnimation(0, this.spineInfo.animName, false);
    this.addChild(this.spine);

    this.spine.state.addListener({
      end: (entry: TrackEntry) => {
        if (entry.animation?.name === this.spineInfo.inAnim01 || entry.animation?.name === this.spineInfo.inAnim02) {
          //win count anim
          eventManager.emit(EventTypes.PACHIDROP_SHOW_WIN_COUNT);
        }
      },
    });
  }

  public reset(): void {
    this.spine.state.setAnimation(0, this.spineInfo.animName, false);
    this.setSkin('def');
  }

  public startCoinInAnimation(_coinType: PachiDropCoinType) {
    this.spine.state.setAnimation(0, this.spineInfo.inAnim01);
    this.spine.state.addAnimation(0, this.spineInfo.animName, false);

    AudioApi.play({ type: ISongs.XT002S_Coin_Hit_pay });
  }

  public setSkin(skinName: string): void {
    this.spine.skeleton.setSkinByName(skinName);
    this.spine.skeleton.setToSetupPose();
  }

  public setType(type: PachiDropPrizeType): void {
    if (this.type != type) {
      this.type = type;
      this.spineInfo = PACHIDROP_PRIZE_SPINE[type];
      this.setSkin(this.spineInfo.skinName);
      this.spine.state.setAnimation(0, this.spineInfo.skinChangeAnim!);
    }
  }

  public skip() {}
}

export default PachiDropPrize;
