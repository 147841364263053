import { EventTypes } from '../global.d';
import { setIsAutoSpins, setIsBuyFeaturePopupOpened } from '../gql/cache';
import { eventManager } from '../slotMachine/config';

export function fallBackReelPosition() {
  if (setIsBuyFeaturePopupOpened()) {
    eventManager.emit(EventTypes.BUY_FEATURE_CONFIRMED);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  } else {
    window.dispatchEvent(new CustomEvent('placeBetCompleted'));
    eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
    if (setIsAutoSpins()) setIsAutoSpins(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  }
}
