import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setNextResult } from '../../gql/cache';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  GameViewLayout,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  WIN_ANIMATION_INTERVAL,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';
import { IAnimateSlot, animateSlotFactory } from '../slot/animateSlot';
import { isCoinAnimateSlot } from '../slot/animateSlot/coinAnimateSlot';

export class SlotsAnimationContainer extends ViewContainer {
  private slotSymbols: IAnimateSlot[] = [];

  constructor() {
    super();
    this.sortableChildren = true;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.cleanSymbols_StartSpin.bind(this));
    eventManager.on(EventTypes.SETUP_REEL_POSITIONS, this.setupSymbols.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, (spinResult) => {
      this.initSymbols(spinResult);
      this.slotSymbols.forEach((slot) => (slot.visible = true));
    });
    eventManager.addListener(EventTypes.START_FS_WIN_ANIMATION, this.onStartFSWinAnimation.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_DROP_COIN_START, this.onStartDropCoin.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_DROP_COIN_TRACE, this.changeToTraceSkin.bind(this));
    eventManager.addListener(EventTypes.INITIALIZE_COIN_TO_TRACE, this.changeToTraceSkin.bind(this));
  }

  private initSymbols(spinResult: Icon[]) {
    this.cleanSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = animateSlotFactory(spinResult[i * REELS_AMOUNT + j]!.id, 0);
        symbol.x = REEL_WIDTH * j + SLOT_WIDTH / 2 + GameViewLayout.Slot.Left.left;
        symbol.y = SLOT_HEIGHT * i + GameViewLayout.Slot.Left.height / 2 + GameViewLayout.Slot.Left.top;
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private setupSymbols(): void {
    this.initSymbols(setNextResult()!.bet.result.spinResult);
  }

  private cleanSymbols(): void {
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private cleanSymbols_StartSpin(_?: boolean): void {
    this.slotSymbols.forEach((symbol, _) => {
      this.removeChild(symbol);
    });
  }

  // Stop
  private onReelStopped(reelId: number, _: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const slot = this.slotSymbols[i * REELS_AMOUNT + reelId]!;
      slot.visible = true;
      slot.startStopAnimation();
    }
  }

  // Anticipation
  private onAnticipationAnimationStarts(): void {
    this.slotSymbols.forEach((slot) => {
      if (slot.slotId !== SlotId.F) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });
  }

  private resetSlotsTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  // Winning
  private skipWinSlotsAnimation(): void {
    this.animation?.skip();
    this.slotSymbols.forEach((slot) => slot.skip());
    //this.resetAllSlotTint();
  }

  private onStartWinAnimation(nextResult: ISettledBet): void {
    this.showWin(nextResult);
  }

  private animation?: AnimationChain | undefined;

  private showWin(nextResult: ISettledBet): void {
    const { paylines } = nextResult;
    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
    });

    // all line
    const set = new Set<number>();
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });
    const allSlotsHighlight = this.highlightSlots(Array.from(set));
    const allSlotsHighlight2 = this.highlightSlots(Array.from(set));

    allSlotsHighlight.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
      //this.setWinSlotTint(Array.from(set));
    });
    allSlotsHighlight2.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      //this.resetAllSlotTint();
    });

    this.animation.appendAnimation(allSlotsHighlight);
    this.animation.appendAnimation(allSlotsHighlight2);

    const eachSlotsHighlight = this.createHighlightChainAnimation(paylines, true);
    this.animation.appendAnimation(eachSlotsHighlight);
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((slotPos) => {
      animationGroup.addAnimation(this.slotSymbols[slotPos]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions);

      chain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        //        this.setWinSlotTint(payline.winPositions);
      });

      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
        //this.resetAllSlotTint();
      });
      animationChain.appendAnimation(chain);
    });
    animationChain.appendAnimation(Tween.createDelayAnimation(WIN_ANIMATION_INTERVAL));
    return animationChain;
  }
  /*
  private resetAllSlotTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }
  private setWinSlotTint(slotPositions: number[]): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = WIN_SLOT_TINT_COLOR;
    });
    slotPositions.forEach((slot) => {
      this.slotSymbols[slot]!.tint = 0xffffff;
    });
  }
*/

  private onStartFSWinAnimation(): void {
    this.animation = new AnimationChain();
    const scatterAnim = new AnimationGroup();
    const delayAnimation = Tween.createDelayAnimation(700);

    this.animation.appendAnimation(delayAnimation);
    this.slotSymbols.forEach((slot) => scatterAnim.addAnimation(slot.getWinAnimation()));

    scatterAnim.addOnStart(() => {
      AudioApi.play({ type: ISongs.XT002S_FeatureTrigger, stopPrev: true });
    });
    scatterAnim.addOnComplete(() => {
      this.animation = undefined;
    });
    this.animation.appendAnimation(scatterAnim);
    this.animation.start();
  }

  private changeToTraceSkin(reelIndex: number): void {
    const slot = this.slotSymbols[reelIndex]!;
    if (isCoinAnimateSlot(slot)) slot.setSkinTrace();
  }

  private onStartDropCoin(reelIndex: number): void {
    const slot = this.slotSymbols[reelIndex]!;
    if (isCoinAnimateSlot(slot)) slot.startDropAnimation();
  }
}
