import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section>
      <div className={styles['payLines']!}>
        <h1 className={styles['title']!}>{t('infoPayLinesTitle')}</h1>
        <div className={`${styles['p']!} ${styles['center']!}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('infoPayLinesDesc'),
            }}
          />
        </div>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoPaylines)} alt="payLines" />
      </div>
    </section>
  );
};

export default PayLines;
