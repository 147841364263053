import { BetBonusReward, BetReward, BonusStatus, EventTypes, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setIsFreeRoundBonus,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { getUserBonuses } from '../../gql/query';
import { eventManager } from '../config';

export const isGetFreeSpinBonus = (nextResult: ISettledBet) => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

  const freeSPinBonus = nextResult?.rewards?.filter(isBonusReward).find((reward) => {
    return reward?.userBonus?.bonus?.type === 'FREE_SPIN';
  })?.userBonus;

  return freeSPinBonus ? true : false;
};

export const getFreeRoundBonus = (nextResult: ISettledBet) => {
  return nextResult?.bet?.data?.bonuses?.find((e) => e.isFreeBet);
};

export const getUserActiveBonuses = async () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};

export const updateCoinValueFromFreeRoundBonus = () => {
  setCoinValue(setFreeRoundBonus().coinValue);
  setCoinAmount(setFreeRoundBonus().coinAmount);
  setBetAmount(setFreeRoundBonus().coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const updateFreeRoundsTotalWin = (winAmount: number) => {
  if (!setIsFreeRoundBonus()) return;

  if (winAmount > 0) {
    if (setFreeRoundsTotalWin() === 0) {
      setFreeRoundsTotalWin(winAmount);
    } else {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
    }
    eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
  }
};
