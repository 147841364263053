export type PachiDropPrizeType = 'lose' | 'hit3' | 'hit5' | 'hit6' | 'hit10' | 'v_bs' | 'v_fs';

export type PachiDropCoinType = 'GoldCoin' | 'SilverCoin';

export const pachiDropPrizeLayout: {
  base: {
    winMulti: number[];
    prizes: PachiDropPrizeType[];
    dispPrizes: PachiDropPrizeType[];
  };
  free: {
    winMulti: number[];
    prizes: PachiDropPrizeType[];
    dispPrizes: PachiDropPrizeType[];
  };
} = {
  base: {
    winMulti: [5, 0, 3, 0, 0, 0, 3, 0, 5],
    prizes: ['hit5', 'lose', 'hit3', 'lose', 'v_bs', 'lose', 'hit3', 'lose', 'hit5'],
    dispPrizes: ['hit5', 'lose', 'hit3', 'lose', 'v_bs', 'lose', 'hit3', 'lose', 'hit5'],
  },
  free: {
    winMulti: [5, 3, 5, 0, 0, 0, 5, 3, 5],
    prizes: ['hit5', 'hit3', 'hit5', 'v_fs', 'v_fs', 'v_fs', 'hit5', 'hit3', 'hit5'],
    dispPrizes: ['hit5', 'hit3', 'hit5', 'lose', 'v_fs', 'lose', 'hit5', 'hit3', 'hit5'],
  },
};

export type PachiDropRoute = 'L' | 'R';

export const PACHIDROP_ROUTE: {
  route: PachiDropRoute[];
  pos: number;
}[] = [
  { route: ['L', 'L', 'L', 'L', 'L', 'L'], pos: 0 },
  { route: ['L', 'L', 'L', 'L', 'L', 'R'], pos: 1 },
  { route: ['L', 'L', 'L', 'L', 'R', 'L'], pos: 1 },
  { route: ['L', 'L', 'L', 'L', 'R', 'R'], pos: 2 },
  { route: ['L', 'L', 'L', 'R', 'L', 'L'], pos: 1 },
  { route: ['L', 'L', 'L', 'R', 'L', 'R'], pos: 2 },
  { route: ['L', 'L', 'L', 'R', 'R', 'L'], pos: 2 },
  { route: ['L', 'L', 'L', 'R', 'R', 'R'], pos: 3 },
  { route: ['L', 'L', 'R', 'L', 'L', 'L'], pos: 1 },
  { route: ['L', 'L', 'R', 'L', 'L', 'R'], pos: 2 },
  { route: ['L', 'L', 'R', 'L', 'R', 'L'], pos: 2 },
  { route: ['L', 'L', 'R', 'L', 'R', 'R'], pos: 3 },
  { route: ['L', 'L', 'R', 'R', 'L', 'L'], pos: 2 },
  { route: ['L', 'L', 'R', 'R', 'L', 'R'], pos: 3 },
  { route: ['L', 'L', 'R', 'R', 'R', 'L'], pos: 3 },
  { route: ['L', 'L', 'R', 'R', 'R', 'R'], pos: 4 },
  { route: ['L', 'R', 'L', 'L', 'L', 'L'], pos: 1 },
  { route: ['L', 'R', 'L', 'L', 'L', 'R'], pos: 2 },
  { route: ['L', 'R', 'L', 'L', 'R', 'L'], pos: 2 },
  { route: ['L', 'R', 'L', 'L', 'R', 'R'], pos: 3 },
  { route: ['L', 'R', 'L', 'R', 'L', 'L'], pos: 2 },
  { route: ['L', 'R', 'L', 'R', 'L', 'R'], pos: 3 },
  { route: ['L', 'R', 'L', 'R', 'R', 'L'], pos: 3 },
  { route: ['L', 'R', 'L', 'R', 'R', 'R'], pos: 4 },
  { route: ['L', 'R', 'R', 'L', 'L', 'L'], pos: 2 },
  { route: ['L', 'R', 'R', 'L', 'L', 'R'], pos: 3 },
  { route: ['L', 'R', 'R', 'L', 'R', 'L'], pos: 3 },
  { route: ['L', 'R', 'R', 'L', 'R', 'R'], pos: 4 },
  { route: ['L', 'R', 'R', 'R', 'L', 'L'], pos: 3 },
  { route: ['L', 'R', 'R', 'R', 'L', 'R'], pos: 4 },
  { route: ['L', 'R', 'R', 'R', 'R', 'L'], pos: 4 },
  { route: ['L', 'R', 'R', 'R', 'R', 'R'], pos: 5 },
  { route: ['R', 'L', 'L', 'L', 'L', 'L'], pos: 1 },
  { route: ['R', 'L', 'L', 'L', 'L', 'R'], pos: 2 },
  { route: ['R', 'L', 'L', 'L', 'R', 'L'], pos: 2 },
  { route: ['R', 'L', 'L', 'L', 'R', 'R'], pos: 3 },
  { route: ['R', 'L', 'L', 'R', 'L', 'L'], pos: 2 },
  { route: ['R', 'L', 'L', 'R', 'L', 'R'], pos: 3 },
  { route: ['R', 'L', 'L', 'R', 'R', 'L'], pos: 3 },
  { route: ['R', 'L', 'L', 'R', 'R', 'R'], pos: 4 },
  { route: ['R', 'L', 'R', 'L', 'L', 'L'], pos: 2 },
  { route: ['R', 'L', 'R', 'L', 'L', 'R'], pos: 3 },
  { route: ['R', 'L', 'R', 'L', 'R', 'L'], pos: 3 },
  { route: ['R', 'L', 'R', 'L', 'R', 'R'], pos: 4 },
  { route: ['R', 'L', 'R', 'R', 'L', 'L'], pos: 3 },
  { route: ['R', 'L', 'R', 'R', 'L', 'R'], pos: 4 },
  { route: ['R', 'L', 'R', 'R', 'R', 'L'], pos: 4 },
  { route: ['R', 'L', 'R', 'R', 'R', 'R'], pos: 5 },
  { route: ['R', 'R', 'L', 'L', 'L', 'L'], pos: 2 },
  { route: ['R', 'R', 'L', 'L', 'L', 'R'], pos: 3 },
  { route: ['R', 'R', 'L', 'L', 'R', 'L'], pos: 3 },
  { route: ['R', 'R', 'L', 'L', 'R', 'R'], pos: 4 },
  { route: ['R', 'R', 'L', 'R', 'L', 'L'], pos: 3 },
  { route: ['R', 'R', 'L', 'R', 'L', 'R'], pos: 4 },
  { route: ['R', 'R', 'L', 'R', 'R', 'L'], pos: 4 },
  { route: ['R', 'R', 'L', 'R', 'R', 'R'], pos: 5 },
  { route: ['R', 'R', 'R', 'L', 'L', 'L'], pos: 3 },
  { route: ['R', 'R', 'R', 'L', 'L', 'R'], pos: 4 },
  { route: ['R', 'R', 'R', 'L', 'R', 'L'], pos: 4 },
  { route: ['R', 'R', 'R', 'L', 'R', 'R'], pos: 5 },
  { route: ['R', 'R', 'R', 'R', 'L', 'L'], pos: 4 },
  { route: ['R', 'R', 'R', 'R', 'L', 'R'], pos: 5 },
  { route: ['R', 'R', 'R', 'R', 'R', 'L'], pos: 5 },
  { route: ['R', 'R', 'R', 'R', 'R', 'R'], pos: 6 },
];

export type IPachiDropPrizeSpine = {
  spineName: string;
  animName: string;
  skinName: string;
  inAnim01: string;
  inAnim02?: string;
  bgAnim?: string;
  skinChangeAnim?: string;
  loopAnim?: string;
  coinSkin?: string;
  payMultiply: number;
};

export const PACHIDROP_PRIZE_SPINE: Record<PachiDropPrizeType, IPachiDropPrizeSpine> = {
  lose: {
    spineName: 'Pachi_Hit',
    animName: 'Low_base',
    skinName: 'def',
    inAnim01: 'Low_in',
    skinChangeAnim: 'Low_base',
    payMultiply: 0,
  },
  hit3: {
    spineName: 'Pachi_Hit',
    animName: 'Low_base',
    skinName: 'def',
    inAnim01: 'Low_in',
    skinChangeAnim: 'Low_base',
    bgAnim: 'Low_cmn_back',
    payMultiply: 3,
  },
  hit5: {
    spineName: 'Pachi_Hit',
    animName: 'High_base',
    skinName: 'def',
    inAnim01: 'High_in',
    skinChangeAnim: 'High_base',
    bgAnim: 'High_cmn_back',
    payMultiply: 5,
  },
  hit6: {
    spineName: 'Pachi_Hit',
    animName: 'Low_base',
    skinName: '2bai',
    inAnim01: 'Low_in',
    skinChangeAnim: 'Low_2bai',
    bgAnim: 'Low_cmn_back',
    payMultiply: 6,
  },
  hit10: {
    spineName: 'Pachi_Hit',
    animName: 'High_base',
    skinName: '2bai',
    inAnim01: 'High_in',
    skinChangeAnim: 'High_2bai',
    bgAnim: 'High_cmn_back',
    payMultiply: 10,
  },
  v_bs: {
    spineName: 'Pachi_Hit_V',
    animName: 'BG_base',
    skinName: 'default',
    inAnim01: 'BG_in_01',
    inAnim02: 'BG_in_01',
    loopAnim: 'BG_wait',
    coinSkin: 'default',
    payMultiply: 0,
  },
  v_fs: {
    spineName: 'Pachi_Hit_V',
    animName: 'FS_base',
    skinName: 'default',
    inAnim01: 'FS_in_01',
    inAnim02: 'FS_in_01',
    loopAnim: 'FS_wait',
    coinSkin: 'default',
    payMultiply: 0,
  },
};

export enum PachiDropPrio {
  BG = 0,
  COIN_FALL = 1,
  BG_FRONT = 2,
  POT_BG = 3,
  COIN_POT = 4,
  POT = 5,
  ALTER = 6,
  COIN_ALTER = 7,
}
export const PACHIDROP_COINDROP_DELAY = 500;
