import _ from 'lodash';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, ReelStopSndType, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_FIRST_ROLLING_DURATION,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_ROLLING_SPEED,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_ROLLING_DURATION,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_FIRST_ROLLING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_ROLLING_SPEED,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';

import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public stopSoundSymbolNo = 0;

  public isLongSpin = false;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    this.container.x = id * REEL_WIDTH;
    this.container.y = 0;
    this.container.sortableChildren = true;
    this.createSlots();
    this.position = this.size - startPosition;
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.REMOVE_ANTICIPATION_TINT, this.resetSlotsTint.bind(this));
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  private onAnticipationStart(index: number): void {
    if (this.id !== index) return;
    this.isLongSpin = true;
    AudioApi.play({ type: ISongs.XT002S_Longspin, stopPrev: true });
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length]!;
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  private getRollingDuration(_: GameMode): number {
    if (this.isTurboSpin) {
      return TURBO_REEL_ROLLING_DURATION + this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL;
    }

    return BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingSpeed = isTurboSpin ? TURBO_REEL_ROLLING_SPEED : BASE_REEL_ROLLING_SPEED;
    const rollingTime = this.getRollingDuration(setGameMode());
    const target = this.position + Math.round(rollingTime * rollingSpeed);

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const firstRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + Math.round(BASE_REEL_FIRST_ROLLING_DURATION * rollingSpeed),
      duration: isTurboSpin ? TURBO_REEL_FIRST_ROLLING_DURATION : BASE_REEL_FIRST_ROLLING_DURATION,
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + Math.round(FAKE_ROLLING_DURATION * rollingSpeed),
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
      this.resetSlotTextureFromCoin();
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
      this.resetSlotTextureFromCoin();
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      firstRollingAnimation: firstRolling,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStop(): void {
    if (this.isLongSpin) {
      AudioApi.stop({ type: ISongs.XT002S_Longspin });
    }
    this.isLongSpin = false;
    if (this.id === REELS_AMOUNT - 1) {
      AudioApi.stop({ type: ISongs.XT002S_SpinStart_Loop });
    }
    if (this.isPlaySoundOnStop) {
      //scatter sound
      if (this.stopSoundSymbolNo >= ReelStopSndType.Scatter1 && this.stopSoundSymbolNo <= ReelStopSndType.Scatter3) {
        AudioApi.play({
          type: ISongs.XT002S_FS_Stop,
          stopPrev: true,
        });
        eventManager.emit(EventTypes.REEL_SC_STOPPED, this.id, this.stopSoundSymbolNo);
      } else {
        const SPINSTOP_SOUND_LIST: Record<SlotId, ISongs> = {
          A: ISongs.XT002S_SpinStop1,
          B: ISongs.XT002S_SpinStop1,
          C: ISongs.XT002S_SpinStop1,
          X: ISongs.XT002S_SpinStop1,
          S: ISongs.XT002S_Bonus_1,
          G: ISongs.XT002S_Bonus_1,
          F: ISongs.XT002S_SpinStop1,
        };
        const slotId = this.getReelStopSlots(Math.round(this.position))[1]!.slotId;
        AudioApi.play({
          type: SPINSTOP_SOUND_LIST[slotId],
          stopPrev: true,
        });
      }
      this.isPlaySoundOnStop = false;
      this.stopSoundSymbolNo = 0;
    }
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id, this.stopSoundSymbolNo);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFirstRolling().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
    this.resetSlotTextureFromCoin();
  }

  private getReelStopSlots(position: number): Slot[] {
    const slots: Slot[] = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    const extra = (this.slots.length - ((position % this.slots.length) - 2)) % this.slots.length;
    slots.push(this.slots[top]!);
    slots.push(this.slots[middle]!);
    slots.push(this.slots[bottom]!);
    slots.push(this.slots[extra]!);
    return slots;
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j]!;
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT - SLOT_HEIGHT;
      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }

  public initChangeSlotTextureToCoin(): void {
    const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
    const slot = reelStopSlots[1]!;
    if (slot.slotId === SlotId.S || slot.slotId === SlotId.G) {
      slot.changeTextureToCoin(true);
    }
  }

  public changeSlotTextureToCoin(): void {
    const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
    reelStopSlots.forEach((slot) => {
      if (slot.slotId === SlotId.S || slot.slotId === SlotId.G) {
        slot.changeTextureToCoin(true);
      }
    });
  }

  public resetSlotTextureFromCoin(): void {
    this.slots.forEach((slot) => {
      if (slot.slotId === SlotId.S || slot.slotId === SlotId.G) {
        slot.changeTextureToCoin(false);
      }
    });
  }
}

export default Reel;
