import * as PIXI from 'pixi.js';

import { MessageFreeSpinsBannerProps } from '../../global';
import i18n from '../../i18next';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_FS_BANNER_DESCRIPTION_Y,
  MESSAGE_FS_BANNER_FEAT_NAME_Y,
  MESSAGE_FS_BANNER_FIG_POSITIONS,
  MESSAGE_FS_BANNER_PRESS_Y,
  MESSAGE_FS_BANNER_TITLE_Y,
  MESSAGE_FS_BANNER_YOU_WON_Y,
} from './config';
import {
  btnTextStyle,
  descriptionTextStyle,
  featureNameTextStyle,
  titleTextStyle,
  youWonTextStyle,
} from './textStyles';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected override props: MessageFreeSpinsBannerProps;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.scale.set(1 / 0.9);
    this.props = props;
  }

  public override init(): MessageFreeSpinsBanner {
    super.init();

    const title = this.initCongratulations(this.props.title);
    const youWon = this.initYouWon();
    const featureName = this.initFeatureName();
    const fig = this.initFigure();
    const description = this.initDescription(this.props.description);
    const btn = this.initBtn(this.props.btnText);

    this.addChild(title, youWon, featureName, fig, description, btn);
    return this;
  }

  private initCongratulations(titleString: string): PIXI.Text {
    const title = new PIXI.Text(titleString, titleTextStyle);
    title.anchor.set(0.5);
    title.y = MESSAGE_FS_BANNER_TITLE_Y;

    return title;
  }

  private initYouWon(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('freeSpinsBanner.youWon'), youWonTextStyle);
    title.anchor.set(0.5);
    title.y = MESSAGE_FS_BANNER_YOU_WON_Y;

    return title;
  }

  private initFeatureName(): PIXI.Text {
    const description = new PIXI.Text(i18n.t<string>('freeSpinsBanner.featureName'), featureNameTextStyle);
    description.anchor.set(0.5);
    description.y = MESSAGE_FS_BANNER_FEAT_NAME_Y;

    return description;
  }

  private initFigure(): PIXI.Sprite {
    const fig = new PIXI.Sprite(
      /*PIXI.Loader.shared.resources['Base_background']!.spineAtlas!.findRegion(
        'bornus_start_pict',
      ).texture,
      */
      undefined,
    );
    fig.position.set(...MESSAGE_FS_BANNER_FIG_POSITIONS);

    return fig;
  }

  private initDescription(descString: string): PIXI.Text {
    const description = new PIXI.Text(descString, descriptionTextStyle);
    description.anchor.set(0.5);
    description.y = MESSAGE_FS_BANNER_DESCRIPTION_Y;

    return description;
  }

  private initBtn(btnString: string): PIXI.Text {
    const btn = new PIXI.Text(btnString, btnTextStyle);
    btn.anchor.set(0.5);
    btn.y = MESSAGE_FS_BANNER_PRESS_Y;

    return btn;
  }
}
