export enum ResourceTypes {
  bgBottom = 'bgBottom',
  bgBottomHuti = 'bgBottomHuti',
  buyBotton = 'buyBotton',
  buyBottonClick = 'buyBottonClick',
  buyBottonDisable = 'buyBottonDisable',
  buyBottonOver = 'buyBottonOver',
  bannerFsBuy1 = 'bannerFsBuy1',
  bannerFsBuy1A = 'bannerFsBuy1A',
  bannerFsTriger = 'bannerFsTriger',
  buyFeatureBtnCancel = 'buyFeatureBtnCancel',
  buyFeatureBtnCancelClick = 'buyFeatureBtnCancelClick',
  buyFeatureBtnCancelOver = 'buyFeatureBtnCancelOver',
  buyFeatureBtnM = 'buyFeatureBtnM',
  buyFeatureBtnMClick = 'buyFeatureBtnMClick',
  buyFeatureBtnMDisable = 'buyFeatureBtnMDisable',
  buyFeatureBtnMOver = 'buyFeatureBtnMOver',
  buyFeatureBtnOk = 'buyFeatureBtnOk',
  buyFeatureBtnOkClick = 'buyFeatureBtnOkClick',
  buyFeatureBtnOkDisable = 'buyFeatureBtnOkDisable',
  buyFeatureBtnOkOver = 'buyFeatureBtnOkOver',
  buyFeatureBtnP = 'buyFeatureBtnP',
  buyFeatureBtnPClick = 'buyFeatureBtnPClick',
  buyFeatureBtnPDisable = 'buyFeatureBtnPDisable',
  buyFeatureBtnPOver = 'buyFeatureBtnPOver',
  messageBigwin = 'messageBigwin',
  messageEpicwin = 'messageEpicwin',
  messageGreatwin = 'messageGreatwin',
  messageMegawin = 'messageMegawin',
  messageWin = 'messageWin',
  largeLine = 'largeLine',
  logo = 'logo',
  reelFrameMask = 'reelFrameMask',
  mediumLine = 'mediumLine',
  shortLine = 'shortLine',
  blurSymbolA = 'blurSymbolA',
  blurSymbolB = 'blurSymbolB',
  blurSymbolBl = 'blurSymbolBl',
  blurSymbolC = 'blurSymbolC',
  blurSymbolD = 'blurSymbolD',
  blurSymbolE = 'blurSymbolE',
  blurSymbolSc = 'blurSymbolSc',
  infoSymbolCoin = 'infoSymbolCoin',
  symbolA = 'symbolA',
  symbolB = 'symbolB',
  symbolBl = 'symbolBl',
  symbolC = 'symbolC',
  symbolD = 'symbolD',
  symbolE = 'symbolE',
  symbolSc = 'symbolSc',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  textLogo = 'textLogo',
}
