import { AutoResizeTextStyle } from '../text/autoResizeText';

const font = 'NotoSans-SemiCondensedBold';

export const grandValueStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: font,
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 64,
  lineHeight: 64,
  lineJoin: 'round',
  fill: ['#fff700'],
  fillGradientStops: [0.42, 0.65],
  stroke: '#000000',
  strokeThickness: 2,
  maxWidth: 248,
};

export const megaValueStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 46,
  lineHeight: 46,
  lineJoin: 'round',
  fill: ['#fff700'],
  fillGradientStops: [0.42, 0.65],
  stroke: '#000000',
  strokeThickness: 2,
  maxWidth: 186,
};

export const majorValueStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 36,
  lineHeight: 36,
  lineJoin: 'round',
  fill: ['#fff700'],
  fillGradientStops: [0.42, 0.65],
  stroke: '#000000',
  strokeThickness: 2,
  maxWidth: 159,
};
