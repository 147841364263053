import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config/audio';
import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { REEL_MARGIN_LEFT, REEL_WIDTH, SLOT_HEIGHT, SLOT_WIDTH, eventManager } from '../config';
import { Icon } from '../d';

import { AlpacaType, LOTS_SCATTER_ANNOUNCE_DENOM, LOTTBL_SC_ANNOUNCE, SC_ANNOUNCE_REEL_NUM } from './config';
import { getResultFromTbl } from './utils';

export class AnnouceContainer extends ViewContainer {
  private slotSpine: Spine[] = [];

  private animChain!: AnimationChain;

  constructor(_spinResult: Icon[]) {
    super();
    for (let reel = 0; reel < SC_ANNOUNCE_REEL_NUM; reel++) {
      const spine = new Spine(PIXI.Loader.shared.resources['ReelFrame_alpaca']!.spineData!);
      spine.x = REEL_WIDTH * reel + SLOT_WIDTH / 2 + REEL_MARGIN_LEFT;
      spine.y = SLOT_HEIGHT * 1 + SLOT_HEIGHT;

      spine.visible = false;
      this.addChild(spine);
      this.slotSpine.push(spine);
    }

    this.animChain = new AnimationChain();

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupScatterAnnounce.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.reelStoped.bind(this));
  }

  private setupScatterAnnounce(
    _reelPositions: number[],
    _stopSoundSymbolNo: number[],
    _anticipationStartReelId: number,
    scAnnounceType: number,
  ): void {
    switch (scAnnounceType) {
      case AlpacaType.Non:
        break;
      case AlpacaType.low:
        this.createLowAnimation();
        break;
      case AlpacaType.Hi:
        this.createHiAnimation();
        break;
    }
  }

  private createLowAnimation() {
    this.animChain = new AnimationChain();

    const startDelay = Tween.createDelayAnimation(50);
    const alpacaLowAnimation = Tween.createDelayAnimation(3000);

    startDelay.addOnComplete(() => {});

    alpacaLowAnimation.addOnStart(() => {
      this.slotSpine[1]!.visible = true;
      // animation
      this.slotSpine[1]!.state.setAnimation(0, 'in');
      this.slotSpine[1]!.state.addAnimation(0, 'wait');
      this.slotSpine[1]!.state.addAnimation(0, 'out');

      AudioApi.play({ type: ISongs.XT002S_Alpaca_Low });
    });
    alpacaLowAnimation.addOnComplete(() => {
      this.slotSpine[1]!.visible = false;
    });

    this.animChain.appendAnimation(startDelay);
    this.animChain.appendAnimation(alpacaLowAnimation);
    this.animChain.start();
  }

  private createHiAnimation() {
    this.animChain = new AnimationChain();

    const startDelay = Tween.createDelayAnimation(50);
    const alpacaLowAnimation = Tween.createDelayAnimation(3000);
    const announceDelay = Tween.createDelayAnimation(500);
    const alpacaHiAnimation = Tween.createDelayAnimation(3000);

    startDelay.addOnComplete(() => {});

    alpacaLowAnimation.addOnStart(() => {
      this.slotSpine[1]!.visible = true;
      // animation
      this.slotSpine[1]!.state.setAnimation(0, 'in');
      this.slotSpine[1]!.state.addAnimation(0, 'wait');
      this.slotSpine[1]!.state.addAnimation(0, 'out');
      AudioApi.play({ type: ISongs.XT002S_Alpaca_Low });
    });
    alpacaLowAnimation.addOnComplete(() => {
      this.slotSpine[1]!.visible = false;
    });

    alpacaHiAnimation.addOnStart(() => {
      this.slotSpine.forEach((slot) => {
        slot.visible = true;
        slot.state.setAnimation(0, 'in');
        slot.state.addAnimation(0, 'wait');
        slot.state.addAnimation(0, 'out');
      });
      AudioApi.play({ type: ISongs.XT002S_Alpaca_High });
    });
    alpacaHiAnimation.addOnComplete(() => {
      this.slotSpine.forEach((slot) => {
        slot.visible = false;
      });
    });

    this.animChain.appendAnimation(startDelay);
    this.animChain.appendAnimation(alpacaLowAnimation);
    this.animChain.appendAnimation(announceDelay);
    this.animChain.appendAnimation(alpacaHiAnimation);
    this.animChain.start();
  }

  private lotsScatterAnnounce(stopScType: number): number {
    const rand = Math.floor(Math.random() * LOTS_SCATTER_ANNOUNCE_DENOM);
    return getResultFromTbl(LOTTBL_SC_ANNOUNCE[stopScType]!, rand);
  }

  private reelStoped(reelId: number, _stopSoundSymbolNo: number): void {
    this.slotSpine[reelId]!.visible = false;
    if (reelId === 2) {
      this.animChain.animations.forEach((anim) => {
        anim.skip();
      });
      this.animChain.end();
      AudioApi.stop({ type: ISongs.XT002S_Alpaca_Low });
      AudioApi.stop({ type: ISongs.XT002S_Alpaca_High });
    }
  }

  private hideContainer(): void {
    this.slotSpine.forEach((spine) => {
      spine.visible = false;
    });
  }
}
