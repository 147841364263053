import { Event, Spine, TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';
import { IPointData } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { GameViewLayout, eventManager } from '../config';

import { PachiDropPrio, PachiDropPrizeType, PachiDropRoute } from './config';

export type CoinSkin = 'SC' | 'GC';

type ICoinTransform = {
  pos: IPointData;
  rotation: number;
};

const DROP_FIRST_Y = 73;
const DROP_ROTATION = 75;
const DROP_POSITION_X = 73;
const DROP_POSITION_Y = 118;

class PachiDropCoin extends PIXI.Container {
  private spine: Spine;

  private skin!: CoinSkin;

  private coinStartData: ICoinTransform[];

  private dropCount: number;

  private prize: PachiDropPrizeType;

  constructor(skin: CoinSkin = 'SC') {
    super();
    this.skin = skin;
    this.spine = new Spine(PIXI.Loader.shared.resources['DropCoin']!.spineData!);
    this.spine.skeleton.setSkinByName(skin);
    this.spine.pivot.set(-GameViewLayout.PachiDrop.Coin.width / 2, -GameViewLayout.PachiDrop.Coin.height / 2);
    this.addChild(this.spine);
    this.setSkin(skin);
    this.spine.visible = false;
    this.dropCount = 0;

    this.prize = 'lose';

    this.coinStartData = [];
    this.spine.state.timeScale = 1.0;

    this.spine.state.addListener({
      start: (entry: TrackEntry) => {
        const startData = this.coinStartData[this.dropCount]!;
        this.spine.position.set(startData.pos.x, startData.pos.y);
        this.spine.skeleton.findBone('prg_rot').rotation = startData.rotation;
        this.zIndex = PachiDropPrio.COIN_FALL;

        if (entry.animation?.name.includes('_fin_saidan')) {
          this.zIndex = PachiDropPrio.COIN_ALTER;
        } else if (entry.animation?.name.includes('_fin') && this.prize !== 'lose') {
          this.zIndex = PachiDropPrio.COIN_POT;
        }
      },
      event: (_entry: TrackEntry, event: Event) => {
        if (event.data.name === 'drop_coin') {
          eventManager.emit(EventTypes.PACHIDROP_PRIZE_ANIM_START);
        }
      },
      complete: (entry: TrackEntry) => {
        this.dropCount = Math.min(this.dropCount + 1, 7);

        if (entry.animation?.name.includes('_fin_saidan')) {
          this.spine.visible = false;
        } else if (entry.animation?.name.startsWith('_fin', 1)) {
          this.spine.visible = false;
          eventManager.emit(EventTypes.PACHIDROP_PRIZE_ANIM_START);
        }
        //        AudioApi.play({ type: ISongs.XT002S_Coin_Pop });
      },
    });
  }

  public setSkin(skinName: CoinSkin) {
    if (this.skin === skinName) return;
    this.skin = skinName;
    this.spine.skeleton.setSkinByName(this.skin);
  }

  public startDropAnimation(startPos: number, route: PachiDropRoute[], prize: PachiDropPrizeType) {
    this.spine.visible = true;
    this.dropCount = 0;
    this.prize = prize;
    this.spine.position.set(0, 0);
    this.createStartPositions(startPos, route);
    this.spine.state.setAnimation(0, 'start');
    route.forEach((type, index) => {
      let animName: string = type;
      if (index === 5) {
        animName = `${animName}_fin`;
        if (prize === 'v_bs' || prize === 'v_fs') {
          animName = `${animName}_saidan`;
        }
      }
      this.spine.state.addAnimation(0, animName);
    });
    this.spine.state.addEmptyAnimation(0);
    AudioApi.play({ type: ISongs.XT002S_Coin_Pop });
  }

  private createStartPositions(coinIndex: number, route: PachiDropRoute[]) {
    let tempPos = {
      x: coinIndex * (GameViewLayout.PachiDrop.Coin2.left - GameViewLayout.PachiDrop.Coin.left),
      y: 0,
    };
    let rotation = 0;
    this.coinStartData = [];

    this.coinStartData.push({
      pos: { ...tempPos },
      rotation: rotation,
    });
    //start Drop
    tempPos.y = DROP_FIRST_Y;
    this.coinStartData.push({
      pos: { ...tempPos },
      rotation: rotation,
    });

    route.forEach((type, _index) => {
      if (type === 'L') {
        rotation += DROP_ROTATION;
        tempPos = {
          x: tempPos.x - DROP_POSITION_X,
          y: tempPos.y + DROP_POSITION_Y,
        };
      } else {
        rotation += -DROP_ROTATION;
        tempPos = {
          x: tempPos.x + DROP_POSITION_X,
          y: tempPos.y + DROP_POSITION_Y,
        };
      }
      this.coinStartData.push({
        pos: { ...tempPos },
        rotation: rotation,
      });
    });
  }
}

export default PachiDropCoin;
