import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setCurrentBonus,
  setIsBuyFeaturePopupOpened,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
} from '../../gql/cache';
import { getGameModeByBonusId, isFreeSpinsMode } from '../../utils';
import Button from '../button';
import { eventManager } from '../config';

class InfoBtn extends Button {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isPopupFreeRoundsOpened: boolean;

  constructor() {
    super('infomation', 75);
    this.interactive = !this.isDisabled;
    this.btnSheet = PIXI.Loader.shared.resources['infoBtnSprite']!.spritesheet!;
    this.gameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : GameMode.REGULAR;
    const hitAreaWidth = this.width * 1.8;
    this.hitArea = new PIXI.Rectangle(-hitAreaWidth / 2, -hitAreaWidth / 2, hitAreaWidth, hitAreaWidth);
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.isPopupFreeRoundsOpened = false;

    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE) {
      setIsModalOpeningInProgress(ModalOpeningTypes.MENU);

      setIsOpenMenuModal({
        isOpen: !setIsOpenMenuModal().isOpen,
        type: 'info',
      });
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      isFreeSpinsMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isFreeSpinsWin ||
        this.isSlotBusy ||
        this.isPopupFreeRoundsOpened ||
        setIsBuyFeaturePopupOpened(),
    );
  };
}

export default InfoBtn;
