import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { easeInOutQuart, isFreeSpinsMode } from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { DURATION_BASE_TO_PACHIDROP, DURATION_PACHIDROP_TO_BASE, eventManager } from '../config';

export type BgSkin = 'default' | 'freespins';

//const BLUR_STRENGTH = 5;

class Background extends PIXI.Container {
  private bgSpines: Record<BgSkin, Spine> = {
    default: new Spine(PIXI.Loader.shared.resources['BG_Basegame']!.spineData!),
    freespins: new Spine(PIXI.Loader.shared.resources['BG_Freespins']!.spineData!),
  };

  private currentSkin?: BgSkin;

  //  private blurFilter: InstanceType<typeof PIXI.filters.BlurFilter>;

  constructor(skin: BgSkin = 'default') {
    super();

    this.addChild(this.bgSpines.default, this.bgSpines.freespins);

    this.setSkin(skin);
    this.bgSpines.default.state.setAnimation(0, 'base', true);
    this.bgSpines.freespins.state.setAnimation(0, 'base', true);

    //    this.blurFilter = new PIXI.filters.BlurFilter(BLUR_STRENGTH);
    //    this.filters = [this.blurFilter];
    //    this.setSkin(isFreeSpinsMode(setGameMode()) ? 'freespins' : 'default');
    this.setSkin(isFreeSpinsMode(setGameMode()) ? 'freespins' : 'default');

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_ZOOM_START, this.startZoomAnimation.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_END, this.onPachiDropEnd.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_WHEEL_VIEW, this.setPachiDropView.bind(this));

    this.scale.set(1.1);
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;
    this.currentSkin = skinName;
    for (const key in this.bgSpines) {
      this.bgSpines[key as BgSkin]!.visible = key === skinName;
    }
  }

  private onChangeMode(settings: { mode: GameMode }) {
    const { mode } = settings;
    this.setSkin(isFreeSpinsMode(mode) ? 'freespins' : 'default');
    /*    if (mode === GameMode.FREE_SPINS) {
      this.blurFilter.blur = 0;
    } else {
      this.blurFilter.blur = BLUR_STRENGTH;
    }
    */
  }

  private startZoomAnimation(isStart: boolean) {
    const startScale = isStart ? 1.1 : 1.0;
    const endScale = isStart ? 1.0 : 1.1;
    //    const startBlur = isStart ? BLUR_STRENGTH : 0;
    //    const endBlur = isStart ? 0 : BLUR_STRENGTH;
    const duration = isStart ? DURATION_BASE_TO_PACHIDROP : DURATION_PACHIDROP_TO_BASE;

    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(
      new Tween({
        object: this.scale,
        property: TweenProperties.X,
        propertyBeginValue: startScale,
        target: endScale,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: this.scale,
        property: TweenProperties.Y,
        propertyBeginValue: startScale,
        target: endScale,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
    /*    animationGroup.addAnimation(
      new Tween({
        object: this.blurFilter,
        property: TweenProperties.BLUR,
        propertyBeginValue: startBlur,
        target: endBlur,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );
*/
    animationGroup.start();

    animationGroup.addOnComplete(() => {});
  }

  private onPachiDropEnd() {
    //    this.blurFilter.blur = BLUR_STRENGTH;
    this.scale.set(1.1);
  }

  private setPachiDropView() {
    //    this.blurFilter.blur = BLUR_STRENGTH;
    this.scale.set(1.0);
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const size = { width: 1920, height: 1080 };
    const bgAspectRatio = size.width / size.height;
    const aspectRatio = width / height;

    let scale = 1.0;
    if (bgAspectRatio > aspectRatio) {
      scale = height / size.height;
    } else {
      scale = width / size.width;
    }
    this.bgSpines.default.scale.set(scale);
    this.bgSpines.freespins.scale.set(scale);
  }
}

export default Background;
