import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../../global.d';
import ViewContainer from '../../components/container';
import { GameViewLayout, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private bgSpine: Spine;

  constructor() {
    super();

    this.bgSpine = new Spine(PIXI.Loader.shared.resources['ReelFrame_Back']!.spineData!);
    this.bgSpine.position.set(GameViewLayout.ReelBase.left, GameViewLayout.ReelBase.top);
    this.bgSpine.pivot.set(-GameViewLayout.ReelBase.width / 2, -GameViewLayout.ReelBase.height / 2);

    this.addChild(this.bgSpine);

    this.bgSpine.state.setAnimation(0, 'base', true);
    this.bgSpine.stateData.setMix('base', 'longspin', 0.5);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));

    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));

    eventManager.addListener(EventTypes.REEL_SC_STOPPED, this.onReelScStopped.bind(this));

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.onAnticipationAnimationStarts.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.onAnticipationAnimationEnd.bind(this));
  }

  private onModeChange(): void {}

  private onStartSpin(): void {}

  private onReelStopped() {}

  private onReelScStopped() {
    this.bgSpine.state.setAnimation(0, 'longspin');
    this.bgSpine.state.addAnimation(0, 'base', true);
  }

  private onAnticipationAnimationStarts(): void {
    this.bgSpine.state.setAnimation(0, 'longspin', true);
  }

  private onAnticipationAnimationEnd(): void {
    this.bgSpine.state.setAnimation(0, 'base', true);
  }
}

export default ReelsBackgroundContainer;
