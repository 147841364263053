import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import {
  GameViewLayout,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../../slotMachine/config';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import SoundBtn from '../button/soundBtn';
import FadeArea from '../fadeArea/fadeArea';

class OpeningScreen {
  private readonly application: PIXI.Application;

  private static openingScreen: OpeningScreen;

  public static initOpeningScreen = (application: PIXI.Application): void => {
    OpeningScreen.openingScreen = new OpeningScreen(application);
  };

  public static getInstance = (): OpeningScreen => OpeningScreen.openingScreen;

  private spine: Spine;

  private title: Spine;

  private bgSpine: Spine;

  private fadeArea: FadeArea;

  private skipFlg = false;

  private bindedResize = this.resize.bind(this);

  private startY: number;

  private targetY: number;

  private startScale: number;

  private targetScale: number;

  private openingAnim: AnimationChain;

  private soundBtn: SoundBtn;

  private constructor(application: PIXI.Application) {
    this.application = application;
    this.spine = new Spine(PIXI.Loader.shared.resources['Opening']!.spineData!);
    this.title = new Spine(PIXI.Loader.shared.resources['Opening']!.spineData!);
    this.bgSpine = new Spine(PIXI.Loader.shared.resources['OpeningBg']!.spineData!);
    this.soundBtn = SoundBtn.getInstance();
    this.fadeArea = new FadeArea();

    this.application.stage.addChild(this.bgSpine);
    this.application.stage.addChild(this.spine);
    this.application.stage.addChild(this.title);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.fadeArea);

    this.spine.interactive = true;
    this.spine.on('mousedown', () => {
      this.skipAnimations();
    });
    this.spine.on('touchstart', () => {
      this.skipAnimations();
    });

    this.startY = 0;
    this.targetY = 0;
    this.startScale = 1;
    this.targetScale = 1;

    this.openingAnim = new AnimationChain();

    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  public startAnimation() {
    this.title.state.setAnimation(0, 'title');
    this.bgSpine.state.setAnimation(0, 'base');

    const animationG = new AnimationGroup();

    const animationY = new Tween({
      object: this.spine.position,
      property: TweenProperties.Y,
      propertyBeginValue: this.startY,
      duration: 4000,
      target: this.targetY,
    });

    const animationScaleX = new Tween({
      object: this.spine.scale,
      property: TweenProperties.X,
      propertyBeginValue: this.startScale,
      duration: 4000,
      target: this.targetScale,
    });
    const animationScaleY = new Tween({
      object: this.spine.scale,
      property: TweenProperties.Y,
      propertyBeginValue: this.startScale,
      duration: 4000,
      target: this.targetScale,
    });

    const delay = Tween.createDelayAnimation(50);
    delay.addOnComplete(() => {
      AudioApi.play({ type: ISongs.XT002S_Title, stopPrev: false });
    });
    delay.start();

    animationG.addOnStart(() => {});
    animationG.addAnimation(animationY);
    animationG.addAnimation(animationScaleX);
    animationG.addAnimation(animationScaleY);

    this.openingAnim.appendAnimation(Tween.createDelayAnimation(1000));
    this.openingAnim.appendAnimation(animationG);

    this.openingAnim.start();

    this.openingAnim.addOnComplete(() => {
      PIXI.Ticker.shared.addOnce(() => {
        this.skipAnimations();
      });
    });
  }

  private skipAnimations() {
    if (!this.skipFlg) {
      this.skipFlg = true;
      eventManager.emit(EventTypes.START_FADE, 1000, 500);
      window.setTimeout(() => {
        eventManager.emit(EventTypes.HANDLE_DESTROY_OPENING_SCREEN);
        this.destroy();
        //TODO BGM Start?
      }, 1000);
    }
  }

  private calculateSize(width: number, height: number): [number, number, number, number, number] {
    let newWidth = 0;
    let newHeight = 0;

    const isLandscape = width / height >= 1;

    const ratio = isLandscape
      ? SAFE_AREA_LANDSCAPE_WIDTH / SAFE_AREA_LANDSCAPE_HEIGHT
      : SAFE_AREA_PORTRAIT_WIDTH / SAFE_AREA_PORTRAIT_HEIGHT;

    const isRectangleRatio = +(width / height).toFixed(2) >= +ratio.toFixed(2);
    if (isRectangleRatio) {
      newWidth = height * ratio;
      newHeight = height;
    } else {
      newWidth = width;
      newHeight = width / ratio;
    }

    const pivotX = isLandscape ? SAFE_AREA_LANDSCAPE_PIVOT_X : SAFE_AREA_PORTRAIT_PIVOT_X;
    const pivotY = isLandscape ? SAFE_AREA_LANDSCAPE_PIVOT_Y : SAFE_AREA_PORTRAIT_PIVOT_Y;
    const scale = isLandscape
      ? newWidth / (SAFE_AREA_LANDSCAPE_WIDTH + Math.abs(SAFE_AREA_LANDSCAPE_PIVOT_X))
      : newWidth / (SAFE_AREA_PORTRAIT_WIDTH + SAFE_AREA_PORTRAIT_PIVOT_X);

    return [newWidth, newHeight, scale, pivotX, pivotY];
  }

  private resize(width: number, height: number): void {
    const [newWidth, newHeight, scale, pivotX, pivotY] = this.calculateSize(width, height);

    const OPENING_REELBASE_SIZE = { width: 1276, height: 510 };
    const OPENING_REELBASE_WIDTH =
      width > height ? OPENING_REELBASE_SIZE.width - 120 : OPENING_REELBASE_SIZE.width + 55;
    const OPENING_REELBASE_HEIGHT = width > height ? OPENING_REELBASE_SIZE.height : OPENING_REELBASE_SIZE.height + 100;

    const posY = Math.max((height - newHeight) / 2, 0) - pivotY * scale;

    this.startScale = (GameViewLayout.ReelBase.width * scale) / OPENING_REELBASE_WIDTH;
    this.targetScale = (GameViewLayout.ReelBase.width * scale) / OPENING_REELBASE_WIDTH;

    this.startY = height - (this.spine.height * this.startScale) / 2;
    this.targetY =
      (this.spine.height / 2) * this.targetScale - (OPENING_REELBASE_HEIGHT / 2) * scale + posY + 160 * scale;

    this.spine.position.set(width > height ? width / 2 + 60 * scale : width / 2, this.startY);
    this.spine.scale.set(this.startScale);

    const wScale = width > height ? width / 1920 : (width / 1920) * 1.7;
    const hScale = height / 1080;

    this.title.scale.set(wScale);
    this.title.position.set(width / 2, height / 2);

    const bgScale = wScale > hScale ? wScale : hScale;
    this.bgSpine.scale.set(bgScale);
    this.bgSpine.position.set(width / 2, height / 2);

    eventManager.emit(EventTypes.RESIZE_GAME_CONTAINER, newWidth, newHeight, 0, 0, scale, pivotX, pivotY);
  }

  private destroy(): void {
    this.application.stage.removeChild(this.bgSpine);
    this.application.stage.removeChild(this.spine);
    this.application.stage.removeChild(this.title);
    this.application.stage.removeChild(this.fadeArea);
    this.openingAnim.end();
    this.spine.destroy();
    this.title.destroy();
    this.bgSpine.destroy();
    this.fadeArea.destroy();
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default OpeningScreen;
