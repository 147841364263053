import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../config';
import {
  BgmSoundTypes,
  EventTypes,
  GameMode,
  MessageFreeSpinsBannerProps,
  MessageWinBannerProps,
} from '../../global.d';
import {
  setBetAmount,
  setBrokenGameBaseWheel,
  setBrokenGameFreeSpinsWheel,
  setCurrency,
  setCurrentBonus,
  setGameMode,
  setIsDuringWheel,
  setLastRegularWinAmount,
  setWheelBonuses,
} from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import {
  easeInOutQuart,
  formatNumber,
  isFreeSpinsMode,
  normalizeCoins,
  resetWheelBonus,
  showCurrency,
} from '../../utils';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import Backdrop from '../backdrop/backdrop';
import BgWall from '../background/bgWall';
import { BgmControl } from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  BASE_WIN_AMOUNT_LIMIT,
  DURATION_BASE_TO_PACHIDROP,
  DURATION_PACHIDROP_TO_BASE,
  GameViewLayout,
  GameViewLayoutBase,
  GameViewState,
  REELBASE_SCALE,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import Jackpot from '../jackpot/jackpot';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import Wheel from '../pachiDrop/moneyWheel/wheel';
import PachiDrop from '../pachiDrop/pachiDrop';
import PachiDropUIContainer from '../pachiDrop/pachiDropUIContainer';
import ReelsFrame from '../reels/frame/reelsFrame';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public slotsAllContainer: PIXI.Container;

  public pachiDrop: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public jackpot!: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public announceContainer: PIXI.Container;

  public frame: ReelsFrame;

  public wheel: Wheel;

  private vibeAnimation!: AnimationGroup;

  private blackBoardSlot: Backdrop;

  private blackBoardWheel: Backdrop;

  private bgWall: BgWall;

  private pachiDropCounterContainer: PachiDropUIContainer;

  private slotAllContainerBasePosY: number;

  private slotAllContainerTargetPosY: number;

  private window: PIXI.ISize = { width: 0, height: 0 };

  private state: GameViewState;

  private viewTransitionAnimation!: Tween;

  //  private freespinBanner: MessageFreeSpinsBanner;

  constructor(props: IGameContainer) {
    super();
    this.slotsAllContainer = new PIXI.Container();
    this.pachiDrop = new PachiDrop();

    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.jackpot = new Jackpot();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.announceContainer = props.announceContainer;

    this.slotAllContainerBasePosY = 0;
    this.slotAllContainerTargetPosY = 0;
    this.state = 'Base';

    this.frame = new ReelsFrame();
    this.wheel = new Wheel();
    this.pachiDropCounterContainer = new PachiDropUIContainer();

    this.bgWall = new BgWall();

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.announceContainer);

    const reelMask = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelFrameMask));
    this.slotsContainer.mask = reelMask;

    this.blackBoardSlot = new Backdrop(
      EventTypes.ANTICIPATION_ANIMATIONS_START,
      EventTypes.ANTICIPATION_ANIMATIONS_END,
      300,
    );
    this.blackBoardWheel = new Backdrop(EventTypes.PACHIDROP_WHEEL_ADVENT, EventTypes.PACHIDROP_END, 300);

    this.slotsAllContainer.addChild(this.gameTitle);
    this.slotsAllContainer.addChild(this.pachiDrop);
    this.slotsAllContainer.addChild(this.blackBoardSlot);
    this.slotsAllContainer.addChild(this.reelsBackgroundContainer);
    this.slotsAllContainer.addChild(this.slotsContainer);
    this.slotsAllContainer.addChild(this.slotsContainer.mask);
    this.slotsAllContainer.addChild(this.slotStopDisplayContainer);
    this.slotsAllContainer.addChild(this.frame);
    this.slotsAllContainer.addChild(this.blackBoardWheel);
    this.slotsAllContainer.addChild(this.wheel);
    //    this.slotsAllContainer.addChild(this.pachiDropCounterContainer);

    this.slotsAllContainer.sortableChildren = true;
    this.gameTitle.zIndex = 0;
    this.pachiDrop.zIndex = 1;
    this.blackBoardSlot.zIndex = 2;
    this.reelsBackgroundContainer.zIndex = 3;
    this.slotsContainer.zIndex = 4;
    this.slotStopDisplayContainer.zIndex = 5;
    this.frame.zIndex = 6;
    this.announceContainer.zIndex = 5;
    this.blackBoardWheel.zIndex = 9;
    this.wheel.zIndex = 10;

    // to scale
    this.slotsAllContainer.pivot.x = GameViewLayout.ReelBase.width / 2;
    this.slotsAllContainer.position.x = GameViewLayout.ReelBase.width / 2;

    this.addChild(this.pachiDropCounterContainer);
    this.addChild(this.jackpot);
    this.addChild(this.slotsAllContainer);
    this.addChild(this.bgWall);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    this.sortableChildren = true;
    this.jackpot.zIndex = 0;
    this.slotsAllContainer.zIndex = 1;
    this.bgWall.zIndex = 3;
    this.coinsAnimationContainer.zIndex = 5;
    this.winLabelContainer.zIndex = 6;
    this.winCountUpMessage.zIndex = 7;
    this.pachiDropCounterContainer.zIndex = 4;

    const gameReplay = new GameReplay();
    gameReplay.zIndex = 999;
    this.addChild(gameReplay);

    eventManager.addListener(EventTypes.RESIZE, this.applicationResize.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_TRIGGER, this.startPachiDropZoomOutAnimation.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_FS_TRIGGER, this.startCoinDrop.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_DROP_END, this.onPachiDropEnd.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_ALL_END, this.endPachiDropWheel.bind(this));

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_START, this.startAnticipationAnimation.bind(this));

    eventManager.addListener(EventTypes.ANTICIPATION_ANIMATIONS_END, this.endAnticipationAnimation.bind(this));

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsStartMessage.bind(this));

    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createFreeSpinEndMessage.bind(this));

    eventManager.addListener(EventTypes.SET_FREESPINS_VIEW, this.setFreeSpinsView.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_WHEEL_VIEW, this.setWheelView.bind(this));

    eventManager.addListener(EventTypes.REEL_SC_STOPPED, this.onReelScStopped.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {
    //to base
    if (settings.mode === GameMode.REGULAR) {
      this.setBaseGameView();
    }
  }

  private startPachiDropZoomOutAnimation() {
    const animationChain = new AnimationChain();
    const animationGroup = new AnimationGroup();

    animationChain.appendAnimation(Tween.createDelayAnimation(300));

    const targetY =
      this.window.height > this.window.width
        ? this.slotAllContainerTargetPosY
        : GameViewLayout.PachiDrop.ReelBase.top - GameViewLayoutBase.top + this.slotAllContainerBasePosY;

    this.viewTransitionAnimation = new Tween({
      object: this.slotsAllContainer.position,
      property: TweenProperties.Y,
      propertyBeginValue: this.slotAllContainerBasePosY,
      target: targetY,
      duration: DURATION_BASE_TO_PACHIDROP,
      easing: easeInOutQuart,
    });
    animationGroup.addAnimation(this.viewTransitionAnimation);

    animationGroup.addAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1,
        target: 1 / REELBASE_SCALE,
        duration: DURATION_BASE_TO_PACHIDROP,
        easing: easeInOutQuart,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1,
        target: 1 / REELBASE_SCALE,
        duration: DURATION_BASE_TO_PACHIDROP,
        easing: easeInOutQuart,
      }),
    );

    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.PACHIDROP_ZOOM_START, true);
      this.jackpot.zIndex = 2;
      this.pachiDrop.zIndex = 7;
      this.state = 'ToPachiDrop';
      this.pachiDropCounterContainer.onPachiDropViewStart();

      BgmControl.fadeOutAll(1500);
      AudioApi.stop({ type: ISongs.XT002S_Openning });
      AudioApi.play({ type: ISongs.XT002S_Zoom_Out });
    });
    animationGroup.addOnComplete(() => {
      this.startCoinDrop();
    });
    animationChain.appendAnimation(animationGroup);
    animationChain.start();
  }

  private startCoinDrop() {
    //SLOTMACHINE IDLE
    eventManager.emit(EventTypes.PACHIDROP_DROP_START);
    eventManager.emit(EventTypes.PACHIDROP_SET_COIN_CNT_VISIBLE, true);
    eventManager.emit(EventTypes.SET_UI_BUTTON_VISIBLE, false);
    this.jackpot.zIndex = 2;
    this.pachiDrop.zIndex = 7;
    this.state = 'PachiDrop';
    this.resetBasePositionByState(this.state);
    this.wheel.reset();

    if (!isFreeSpinsMode(setGameMode())) {
      BgmControl.stopBgm();
      BgmControl.playBgm(BgmSoundTypes.PACHIDROP);
    }
  }

  private onPachiDropEnd() {
    if (isFreeSpinsMode(setGameMode())) {
      if (setCurrentBonus().totalRounds <= setCurrentBonus().currentRound) {
        if (setWheelBonuses().bonus.length > 0) {
          eventManager.emit(EventTypes.PACHIDROP_SHOW_WHEEL);
        } else {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          eventManager.emit(EventTypes.END_FREESPINS);
        }
      } else {
        eventManager.emit(EventTypes.FREESPINS_PACHIDROP_DROP_END);
      }
    } else {
      //      this.makeWheelBonusInfo();

      if (setWheelBonuses().bonus.length > 0) {
        eventManager.emit(EventTypes.PACHIDROP_SHOW_WHEEL);
        BgmControl.stopBgm();
      } else if (setGameMode() === GameMode.PACHIDROP || setGameMode() === GameMode.REGULAR) {
        this.startPachiDropZoomInAnimation();
      }
    }
    eventManager.emit(EventTypes.SET_UI_BUTTON_VISIBLE, true);
  }

  private startPachiDropZoomInAnimation() {
    const animationGroup = new AnimationGroup();

    const beginY =
      this.window.height > this.window.width
        ? this.slotAllContainerTargetPosY
        : GameViewLayout.PachiDrop.ReelBase.top - GameViewLayoutBase.top + this.slotAllContainerBasePosY;
    this.viewTransitionAnimation = new Tween({
      object: this.slotsAllContainer.position,
      property: TweenProperties.Y,
      propertyBeginValue: beginY,
      target: this.slotAllContainerBasePosY,
      duration: DURATION_PACHIDROP_TO_BASE,
      easing: easeInOutQuart,
    });
    animationGroup.addAnimation(this.viewTransitionAnimation);

    animationGroup.addAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1 / REELBASE_SCALE,
        target: 1,
        duration: DURATION_PACHIDROP_TO_BASE,
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1 / REELBASE_SCALE,
        target: 1,
        duration: DURATION_PACHIDROP_TO_BASE,
      }),
    );

    animationGroup.addOnStart(() => {
      this.state = 'ToBase';
      eventManager.emit(EventTypes.PACHIDROP_ZOOM_START, false);
      this.pachiDropCounterContainer.onPachiDropViewEnd();
      BgmControl.fadeOutAll(900);
      AudioApi.play({ type: ISongs.XT002S_Zoom_In });
    });
    animationGroup.addOnComplete(() => {
      eventManager.emit(EventTypes.FREE_ROUND_BONUS_EXPIRE_CHECK);
      this.setBaseGameView();
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(setCurrency(), normalizeCoins(setLastRegularWinAmount()), showCurrency(setCurrency())),
      );
    });

    animationGroup.start();
  }

  private endPachiDropWheel() {
    resetWheelBonus();
    setBrokenGameFreeSpinsWheel(false);
    setBrokenGameBaseWheel(false);

    if (isFreeSpinsMode(setGameMode())) {
      setCurrentBonus({ ...setCurrentBonus(), isActive: false });
      eventManager.emit(EventTypes.END_FREESPINS);
      return;
    }

    const wheelEndAnim = Tween.createDelayAnimation(500);
    wheelEndAnim.addOnStart(() => {
      eventManager.emit(EventTypes.START_FADE, 500, 1000, 0x000000);
    });
    wheelEndAnim.addOnComplete(() => {
      eventManager.emit(EventTypes.FREE_ROUND_BONUS_EXPIRE_CHECK);
      this.setBaseGameView();
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber(setCurrency(), normalizeCoins(setLastRegularWinAmount()), showCurrency(setCurrency())),
      );
    });
    wheelEndAnim.start();
  }

  private setBaseGameView() {
    setIsDuringWheel(false);

    this.slotsAllContainer.position = {
      x: GameViewLayout.ReelBase.width / 2,
      y: this.slotAllContainerBasePosY,
    };
    this.slotsAllContainer.scale.set(1.0);
    this.wheel.reset();
    eventManager.emit(EventTypes.PACHIDROP_END);
    eventManager.emit(EventTypes.HIDE_COINS);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    eventManager.emit(EventTypes.SKIP_ALL_WIN_ANIMATIONS);
    eventManager.emit(EventTypes.PACHIDROP_SET_COIN_CNT_VISIBLE, false);
    eventManager.emit(EventTypes.PACHIDROP_SET_FREESPIN_CNT_VISIBLE, false);
    eventManager.emit(EventTypes.PACHIDROP_SETUP_PRIZES);

    this.jackpot.zIndex = 0;
    this.pachiDrop.zIndex = 1;
    this.state = 'Base';
    this.resetBasePositionByState('Base');

    if (!BgmControl.isPlayBgm(BgmSoundTypes.BASE)) {
      BgmControl.stopBgm();
      BgmControl.playBgm(BgmSoundTypes.BASE);
    }
  }

  private onReelScStopped() {
    this.startFSReelStopAnimation();
  }

  private startFSReelStopAnimation() {
    const stopAnim = new AnimationGroup({ isLoop: false });
    const animationChainX = new AnimationChain();
    const animationChainY = new AnimationChain();
    const animationChainXS = new AnimationChain();
    const animationChainYS = new AnimationChain();

    const ANIM_DURATION = 45;
    const TRANS_WIDTH = 0;
    const TRANS_HEIGHT = 6;
    const TRANS_SCALE = 1.0;

    animationChainX.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.X,
        propertyBeginValue: GameViewLayout.ReelBase.width / 2 - TRANS_WIDTH,
        target: GameViewLayout.ReelBase.width / 2 + TRANS_WIDTH,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainX.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.X,
        propertyBeginValue: GameViewLayout.ReelBase.width / 2 + TRANS_WIDTH,
        target: GameViewLayout.ReelBase.width / 2 - TRANS_WIDTH,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );

    animationChainY.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.Y,
        propertyBeginValue: this.slotAllContainerBasePosY - TRANS_HEIGHT,
        target: this.slotAllContainerBasePosY + TRANS_HEIGHT,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainY.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.Y,
        propertyBeginValue: this.slotAllContainerBasePosY + TRANS_HEIGHT,
        target: this.slotAllContainerBasePosY - TRANS_HEIGHT,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );

    animationChainXS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1,
        target: TRANS_SCALE,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainXS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: TRANS_SCALE,
        target: 1,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainYS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1,
        target: TRANS_SCALE,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainYS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: TRANS_SCALE,
        target: 1,
        duration: ANIM_DURATION,
        easing: easeInOutQuart,
      }),
    );
    stopAnim.addAnimation(animationChainX);
    stopAnim.addAnimation(animationChainY);
    stopAnim.addAnimation(animationChainXS);
    stopAnim.addAnimation(animationChainYS);

    stopAnim.start();
    stopAnim.addOnSkip(() => {
      this.slotsAllContainer.position.set(GameViewLayout.ReelBase.width / 2, this.slotAllContainerBasePosY);
    });
    stopAnim.addOnComplete(() => {
      this.slotsAllContainer.position.set(GameViewLayout.ReelBase.width / 2, this.slotAllContainerBasePosY);
    });
  }

  private startAnticipationAnimation() {
    this.vibeAnimation = new AnimationGroup({ isLoop: true });
    const animationChainX = new AnimationChain();
    const animationChainY = new AnimationChain();
    const animationChainXS = new AnimationChain();
    const animationChainYS = new AnimationChain();

    const VIBE_DURATION = 50;
    const VIBE_WIDTH = 2;
    const VIBE_HEIGHT = 3;
    const VIBE_SCALE = 1.0;

    animationChainX.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.X,
        propertyBeginValue: GameViewLayout.ReelBase.width / 2 - VIBE_WIDTH,
        target: GameViewLayout.ReelBase.width / 2 + VIBE_WIDTH,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainX.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.X,
        propertyBeginValue: GameViewLayout.ReelBase.width / 2 + VIBE_WIDTH,
        target: GameViewLayout.ReelBase.width / 2 - VIBE_WIDTH,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );

    animationChainY.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.Y,
        propertyBeginValue: this.slotAllContainerBasePosY - VIBE_HEIGHT,
        target: this.slotAllContainerBasePosY + VIBE_HEIGHT,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainY.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.position,
        property: TweenProperties.Y,
        propertyBeginValue: this.slotAllContainerBasePosY + VIBE_HEIGHT,
        target: this.slotAllContainerBasePosY - VIBE_HEIGHT,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );

    animationChainXS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: 1,
        target: VIBE_SCALE,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainXS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.X,
        propertyBeginValue: VIBE_SCALE,
        target: 1,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainYS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: 1,
        target: VIBE_SCALE,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );
    animationChainYS.appendAnimation(
      new Tween({
        object: this.slotsAllContainer.scale,
        property: TweenProperties.Y,
        propertyBeginValue: VIBE_SCALE,
        target: 1,
        duration: VIBE_DURATION,
        easing: easeInOutQuart,
      }),
    );
    this.vibeAnimation.addAnimation(animationChainX);
    this.vibeAnimation.addAnimation(animationChainY);
    this.vibeAnimation.addAnimation(animationChainXS);
    this.vibeAnimation.addAnimation(animationChainYS);

    const delay = Tween.createDelayAnimation(45);
    delay.addOnComplete(() => {
      this.vibeAnimation.start();
    });
    delay.start();

    this.vibeAnimation.addOnComplete(() => {});
    this.vibeAnimation.addOnSkip(() => {
      this.slotsAllContainer.position.set(GameViewLayout.ReelBase.width / 2, this.slotAllContainerBasePosY);
    });
  }

  private endAnticipationAnimation() {
    this.vibeAnimation.skip();
    this.slotsAllContainer.position.set(GameViewLayout.ReelBase.width / 2, this.slotAllContainerBasePosY);
    this.slotsAllContainer.scale.set(1);
  }

  private setWheelView() {
    setIsDuringWheel(true);
    this.jackpot.zIndex = 2;
    this.pachiDrop.zIndex = 7;
    this.state = 'PachiDrop';
    this.resetBasePositionByState('PachiDrop');
    this.slotsAllContainer.scale.set(1 / REELBASE_SCALE);
    eventManager.emit(EventTypes.PACHIDROP_SET_COIN_CNT_VISIBLE, true);
    eventManager.emit(EventTypes.PACHIDROP_SETUP_PRIZES);
    eventManager.emit(EventTypes.PACHIDROP_SET_FREESPIN_CNT_VISIBLE, false);
    BgmControl.stopBgm();
  }

  private setFreeSpinsView() {
    this.jackpot.zIndex = 2;
    this.pachiDrop.zIndex = 7;
    this.state = 'PachiDrop';
    this.resetBasePositionByState('PachiDrop');
    this.slotsAllContainer.scale.set(1 / REELBASE_SCALE);
    eventManager.emit(EventTypes.PACHIDROP_SET_COIN_CNT_VISIBLE, true);
    eventManager.emit(EventTypes.PACHIDROP_SET_FREESPIN_CNT_VISIBLE, true);
    eventManager.emit(EventTypes.PACHIDROP_SETUP_PRIZES);
  }

  private createFreeSpinsStartMessage(props: MessageFreeSpinsBannerProps) {
    this.setFreeSpinsView();
    const messageBanner = new MessageFreeSpinsBanner(props);
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private createFreeSpinEndMessage(props: MessageWinBannerProps): void {
    const multiplier = props.totalWinAmount / setBetAmount();
    const bgm =
      multiplier >= BASE_WIN_AMOUNT_LIMIT ? ISongs.XT002S_Total_Win_Banner_2 : ISongs.XT002S_Total_Win_Banner_1;

    const totalWinDelay = Tween.createDelayAnimation(mappedAudioSprites[bgm]!.duration);
    totalWinDelay.addOnStart(() => {
      BgmControl.stopBgm();
    });
    totalWinDelay.addOnSkip(() => {
      AudioApi.fadeOut(1000, bgm);
    });
    AudioApi.play({ type: bgm, stopPrev: true });

    totalWinDelay.start();

    const messageBanner = new MessageWinBanner({
      ...props,
      callback: () => {
        totalWinDelay.skip();
        if (props.callback) props.callback();
      },
    });
    messageBanner.init();
    messageBanner.zIndex = 20;
    messageBanner.handleResize(this.window.width, this.window.height);
    this.addChild(messageBanner);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private resize(
    _width: number,
    _height: number,
    _x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);

    this.slotsAllContainer.pivot.y = y * REELBASE_SCALE;
    this.slotsAllContainer.position.y = y * REELBASE_SCALE;

    this.slotAllContainerBasePosY = y * REELBASE_SCALE;
    this.slotAllContainerTargetPosY = y;

    this.resetBasePositionByState(this.state);
  }

  private resetBasePositionByState(state: GameViewState) {
    const isPortrait = this.window.height > this.window.width;
    switch (state) {
      case 'Base':
        this.slotsAllContainer.position.y = this.slotAllContainerBasePosY;
        break;
      case 'ToBase':
        this.viewTransitionAnimation!.target = this.slotAllContainerBasePosY;
        break;
      case 'ToPachiDrop':
        if (isPortrait) {
          this.viewTransitionAnimation!.target = this.slotAllContainerTargetPosY;
        } else {
          this.viewTransitionAnimation!.target =
            GameViewLayout.PachiDrop.ReelBase.top - GameViewLayoutBase.top + this.slotAllContainerBasePosY;
        }
        break;
      case 'PachiDrop':
        if (isPortrait) {
          this.slotsAllContainer.position.y = this.slotAllContainerTargetPosY;
        } else {
          this.slotsAllContainer.position.y =
            GameViewLayout.PachiDrop.ReelBase.top - GameViewLayoutBase.top + this.slotAllContainerBasePosY;
        }
        break;
      default:
        break;
    }
  }
}

export default GameView;
