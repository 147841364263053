import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBetAmount, setCurrency, setIsLeftHandMode, setSlotConfig } from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { formatNumberNonFraction, normalizeCoins, showCurrency } from '../../utils';
import { FLEXIBLE_GAMEVIEW_POSY_RATIO, GameViewLayout, GameViewLayoutBase, eventManager } from '../config';
import AutoResizeText from '../text/autoResizeText';

import { grandValueStyle, majorValueStyle, megaValueStyle } from './textStyle';

const GRAND_MULTIPLY = 3000;
const MEGA_MULTIPLY = 1000;
const MAJOR_MULTIPLY = 500;
class Jackpot extends PIXI.Container {
  private allContainer: PIXI.Container;

  private bg: Spine;

  private grandText: AutoResizeText;

  private megaText: AutoResizeText;

  private majorText: AutoResizeText;

  private linesAmount: number;

  private betSettings: IBetSettings;

  private betAmount: number;

  private window: PIXI.ISize = { width: 0, height: 0 };

  private isLeftHandMode: boolean;

  private gameContainerScale: number;

  private safeAreaPosY: number;

  constructor() {
    super();

    this.allContainer = new PIXI.Container();

    this.bg = new Spine(PIXI.Loader.shared.resources['Jackpot_Frame']!.spineData!);

    this.bg.position.set(0, 0);
    this.bg.pivot.set(-GameViewLayout.JackPot.Bg.width / 2, -GameViewLayout.JackPot.Bg.height / 2);
    this.betSettings = getBetsSetting();
    this.linesAmount = setSlotConfig().lineSet.coinAmountMultiplier;
    this.betAmount = this.getBetAmount(setBetAmount());

    this.grandText = this.initGrandValue();
    this.megaText = this.initMegaValue();
    this.majorText = this.initMajorValue();

    this.allContainer.addChild(this.bg);
    this.allContainer.addChild(this.grandText);
    this.allContainer.addChild(this.megaText);
    this.allContainer.addChild(this.majorText);

    this.allContainer.pivot.set(GameViewLayout.JackPot.Bg.width / 2, GameViewLayout.JackPot.Bg.height / 2);

    this.window = { width: 0, height: 0 };
    this.isLeftHandMode = setIsLeftHandMode();
    this.gameContainerScale = 1;
    this.safeAreaPosY = 0;

    this.addChild(this.allContainer);

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));

    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
    });

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
  }

  private initGrandValue(): AutoResizeText {
    const jackpotValue = new AutoResizeText(this.getJackpotText(GRAND_MULTIPLY), grandValueStyle);
    jackpotValue.x =
      GameViewLayout.JackPot.TextGrand.left -
      GameViewLayout.JackPot.Bg.left +
      GameViewLayout.JackPot.TextGrand.width / 2;
    jackpotValue.y =
      GameViewLayout.JackPot.TextGrand.top -
      GameViewLayout.JackPot.Bg.top +
      GameViewLayout.JackPot.TextGrand.height / 2;
    jackpotValue.anchor.set(0.5);

    return jackpotValue;
  }

  private initMegaValue(): AutoResizeText {
    //TO DO, del part of point
    const jackpotValue = new AutoResizeText(this.getJackpotText(MEGA_MULTIPLY), megaValueStyle);
    jackpotValue.x =
      GameViewLayout.JackPot.TextMega.left - GameViewLayout.JackPot.Bg.left + GameViewLayout.JackPot.TextMega.width / 2;
    jackpotValue.y =
      GameViewLayout.JackPot.TextMega.top - GameViewLayout.JackPot.Bg.top + GameViewLayout.JackPot.TextMega.height / 2;
    jackpotValue.anchor.set(0.5);

    return jackpotValue;
  }

  private initMajorValue(): AutoResizeText {
    const jackpotValue = new AutoResizeText(this.getJackpotText(MAJOR_MULTIPLY), majorValueStyle);

    jackpotValue.x =
      GameViewLayout.JackPot.TextMajor.left -
      GameViewLayout.JackPot.Bg.left +
      GameViewLayout.JackPot.TextMajor.width / 2;
    jackpotValue.y =
      GameViewLayout.JackPot.TextMajor.top -
      GameViewLayout.JackPot.Bg.top +
      GameViewLayout.JackPot.TextMajor.height / 2;
    jackpotValue.anchor.set(0.5);

    return jackpotValue;
  }

  private getJackpotText(multiply: number): string {
    return `${formatNumberNonFraction(
      setCurrency(),
      normalizeCoins(this.getBetValue() * multiply),
      showCurrency(setCurrency()),
    )}`;
  }

  private updateBets(): void {
    this.grandText.text = this.getJackpotText(GRAND_MULTIPLY);
    this.megaText.text = this.getJackpotText(MEGA_MULTIPLY);
    this.majorText.text = this.getJackpotText(MAJOR_MULTIPLY);
  }

  private onModeChange(_settings: { mode: GameMode }) {
    //    this.visible = !isFreeSpinsMode(settings.mode);
  }

  private getBetAmount(betAmount: number): number {
    return this.betSettings!.bets.findIndex((bet) => bet === betAmount / this.linesAmount) + 1;
  }

  private getBetValue(): number {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, y: number, scale: number): void => {
    this.gameContainerScale = scale;
    this.safeAreaPosY = y;
    this.handlePosition();
  };

  private handlePosition(): void {
    if (this.window.height > this.window.width) {
      const baseScale = GameViewLayout.JackPot.Bg.width / GameViewLayout.Portrait.Jackpot.width;

      this.allContainer.scale.set(baseScale);
      this.allContainer.x =
        (GameViewLayout.Portrait.Jackpot.left - GameViewLayout.Portrait.ReelBase.left) * baseScale +
        this.allContainer.width / 2;
      this.allContainer.y =
        (GameViewLayout.Portrait.Jackpot.top - GameViewLayout.Portrait.ReelBase.top) * baseScale +
        this.allContainer.height / 2 -
        70 -
        (this.safeAreaPosY / this.gameContainerScale) * FLEXIBLE_GAMEVIEW_POSY_RATIO;
    } else {
      this.allContainer.scale.set(this.isLeftHandMode ? 0.9 : 1.0);
      this.allContainer.position.set(
        this.isLeftHandMode
          ? GameViewLayout.ReelBase.width - 120 + this.allContainer.width / 2
          : GameViewLayout.JackPot.Bg.left - GameViewLayoutBase.left + this.allContainer.width / 2,
        this.isLeftHandMode
          ? GameViewLayout.JackPot.Bg.top - GameViewLayoutBase.top - 20 + this.allContainer.height / 2
          : GameViewLayout.JackPot.Bg.top - GameViewLayoutBase.top + this.allContainer.height / 2,
      );
    }
  }
}

export default Jackpot;
