import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { GameViewLayout } from '../config';

export type BgSkin = 'default' | 'freespins';

class PachiDropBg extends Spine {
  private anim: string;

  constructor(anim: string) {
    super(PIXI.Loader.shared.resources['Pachi_Borad']!.spineData!);

    this.pivot.set(-GameViewLayout.PachiDrop.BoardBase.width / 2, -GameViewLayout.PachiDrop.BoardBase.height / 2);
    this.anim = '';
    this.setSkin(anim);
  }

  public setSkin(anim: string) {
    if (this.anim === anim) return;
    this.anim = anim;
    this.state.setAnimation(0, this.anim!);
  }
}

export default PachiDropBg;
