import * as PIXI from 'pixi.js';

import { setCurrentBonus } from '../../gql/cache';
import i18n from '../../i18next';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { pachiDropFreeSpinCntStyles, pachiDropFreeSpinStyles } from '../config';
import AutoResizeText from '../text/autoResizeText';

class PachiDropFreeSpinCounter extends PIXI.Container {
  private text: PIXI.Text;

  private cntText: PIXI.Text;

  private cnt: number;

  constructor() {
    super();

    this.cnt = 0;
    this.text = new AutoResizeText(i18n.t<string>('freeSpinsCnt'), pachiDropFreeSpinStyles);
    this.cntText = new PIXI.Text(`${this.cnt}/${setCurrentBonus().totalRounds}`, pachiDropFreeSpinCntStyles);
    this.init();

    this.addChild(this.text);
    this.addChild(this.cntText);
  }

  private init() {
    this.text.anchor.set(1, 0.5);
    this.cntText.anchor.set(0.5);
    this.text.position.set(510, this.cntText.height / 2);
    this.cntText.position.set(520 + this.cntText.width / 2, 0 + this.cntText.height / 2);
  }

  private updateFreeSpinText() {
    this.cntText.text = `${this.cnt}/${setCurrentBonus().totalRounds}`;
  }

  public setCnt(cnt: number) {
    this.cnt = cnt;
    this.updateFreeSpinText();
  }

  public updateCnt(cnt: number) {
    this.cnt = cnt;
    this.updateFreeSpinText();
    if (this.cnt != 0) {
      this.createUpdateTextAnimation(1.4, 1000);
    }
  }

  public reset() {
    this.cnt = 0;
  }

  private createUpdateTextAnimation(target_scale: number, duration: number) {
    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(
      new Tween({
        object: this.cntText.scale,
        property: TweenProperties.X,
        propertyBeginValue: target_scale,
        target: 1,
        duration: duration,
        easing: (x) => {
          return -Math.cos(x * Math.PI) * 0.5 + 0.5;
        },
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: this.cntText.scale,
        property: TweenProperties.Y,
        propertyBeginValue: target_scale,
        target: 1,
        duration: duration,
        easing: (x) => {
          return -Math.cos(x * Math.PI) * 0.5 + 0.5;
        },
      }),
    );
    animationGroup.start();
  }
}

export default PachiDropFreeSpinCounter;
