import { SlotId } from '../config';
import { BetBonusReward, BetReward, BonusKind, GameMode, UserBonus, bonusIds, reelSets } from '../global.d';
import { setIsBuyFeatureSpin, setNextResult, setSlotConfig, setWheelBonuses } from '../gql/cache';
import SpineAnimation from '../slotMachine/animations/spine';
import { Icon } from '../slotMachine/d';

import { nextTick } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || setSlotConfig().lineSet.coinAmountMultiplier)) / 100
  );
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

// const createFastBetArrMax = (minBet: number, maxBet: number) => {
//   const cef = maxBet / minBet / 10;
//   const arr = new Array(10).fill(minBet);
//   return arr.map((item, index) => {
//     const v = index ? index + 1 : 1;
//     const i = Math.floor(cef * v);
//     return item * i;
//   });
// };

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  throw new Error(`NO SUCH ICON FOR ID ${id}`);
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
  window.dispatchEvent(new CustomEvent('setpos'));
};

export const destroySpine = (spine: SpineAnimation): void => {
  nextTick(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy({ children: true });
    }
  });
};
export const isBaseGameMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR || mode === GameMode.BUY_FEATURE;
};
export const isFreeSpinsMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};

export const isScatter = (slotId: SlotId): boolean => {
  return slotId === SlotId.F;
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }

  return GameMode.REGULAR;
};

export const isBuyFeatureMode = (): boolean => {
  return setIsBuyFeatureSpin();
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  const BonusKindToGameMode: Record<string, GameMode> = {
    [BonusKind.BUY_FEATURE]: GameMode.BUY_FEATURE,
    [BonusKind.FREE_SPINS]: GameMode.FREE_SPINS,
    [BonusKind.WHEEL_SILVER]: GameMode.REGULAR,
    [BonusKind.WHEEL_GOLD]: GameMode.REGULAR,
    [BonusKind.FS_WHEEL_SILVER]: GameMode.FREE_SPINS,
    [BonusKind.FS_WHEEL_GOLD]: GameMode.FREE_SPINS,
  };
  const bonusKind = Object.entries(bonusIds).find(([, id]) => id === bonusId)?.[0];

  if (!bonusKind) {
    return GameMode.REGULAR;
  }
  return BonusKindToGameMode[bonusKind] as GameMode;
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  bonusCurrentRound,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isOpenedMessageBanner,
  isInTransition,
  isBuyFeaturePopupOpened,
  isDuringCoinDrop,
  isWheelSpinBtnOn,
  isDuringWheelSpin,
  isPachiDropWinnings,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isOpenedMessageBanner: boolean;
  isInTransition: boolean;
  isBuyFeaturePopupOpened: boolean;
  isDuringCoinDrop: boolean;
  isWheelSpinBtnOn: boolean;
  isDuringWheelSpin: boolean;
  isPachiDropWinnings: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }

  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }
  if (isWheelSpinBtnOn) {
    return true;
  }
  if (isPachiDropWinnings) {
    return true;
  }
  if (isDuringCoinDrop) {
    return false;
  }
  if (isDuringWheelSpin) {
    return false;
  }

  if (isFreeSpinsMode(gameMode) && (bonusCurrentRound === 0 || !isSlotBusy)) {
    if (isOpenedMessageBanner) return true;
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  return true;
};

export const getFreeSpinBonus = (): UserBonus | undefined => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

  return setNextResult()
    ?.rewards.filter(isBonusReward)
    .find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
};

export const resetWheelBonus = (): void => {
  setWheelBonuses({ bonus: [], currentRound: 0, totalRounds: 0 });
};

export const queryParams = new URLSearchParams(window.location.search);

export const isDemo = queryParams.has('isDemo');
