import { ISongs } from '../../config';
import { BASE_REEL_ROLLING_DURATION } from '../config';

export enum AlpacaType {
  Non = 0,
  low = 1,
  Hi = 2,
}

type ScAnnounceType = {
  reelCnt: number;
  reelStopDelay: number;
  snd: string[];
};
const SCANNOUNCE_SND_NML = [ISongs.XT002S_Alpaca_Low, ISongs.XT002S_Alpaca_Low, ISongs.XT002S_Alpaca_High];
export const ScAnnounceData: ScAnnounceType[] = [
  {
    reelCnt: 0,
    reelStopDelay: 0,
    snd: ['', '', ''],
  },
  {
    reelCnt: 1,
    reelStopDelay: 3200 - BASE_REEL_ROLLING_DURATION,
    snd: SCANNOUNCE_SND_NML,
  },
  {
    reelCnt: 2,
    reelStopDelay: 6500 - BASE_REEL_ROLLING_DURATION,
    snd: SCANNOUNCE_SND_NML,
  },
];

export const LOTTBL_SC_ANNOUNCE = [
  [1000, 0, 0], //
  [951, 49, 0], //Reach
  [350, 300, 350], //Bonus
  [650, 350, 0], //GoldCoin
];

export const LOTS_SCATTER_ANNOUNCE_DENOM = 1000;

export const SC_ANNOUNCE_REEL_NUM = 3;
