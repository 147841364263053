import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import { createZoomAnimation } from '../animations/helper';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  BIG_WIN_END_DURATION,
  BIG_WIN_TITLE,
  BIG_WIN_TITLE_SCALE,
  BIG_WIN_ZOOM_TITLE_DURATION,
  EPIC_WIN_TITLE,
  EPIC_WIN_TITLE_SCALE,
  EPIC_WIN_ZOOM_TITLE_DURATION,
  GREAT_WIN_TITLE,
  GREAT_WIN_TITLE_SCALE,
  GREAT_WIN_ZOOM_TITLE_DURATION,
  MEGA_WIN_TITLE,
  MEGA_WIN_TITLE_SCALE,
  MEGA_WIN_ZOOM_TITLE_DURATION,
  WINLABEL_JACKPOT_POS_Y,
  WINLABEL_POS_X,
  WINLABEL_POS_Y,
  WinStages,
  bigWinStyles,
  epicWinStyles,
  eventManager,
  greatWinStyles,
  megaWinStyles,
} from '../config';

class WinLabelContainer extends ViewContainer {
  public bigWinLabel: PIXI.Text;

  public megaWinLabel: PIXI.Text;

  public greatWinLabel: PIXI.Text;

  public epicWinLabel: PIXI.Text;

  public minorWinLabel: Spine;

  public majorWinLabel: Spine;

  public grandWinLabel: Spine;

  private bigWinAnimation: Animation | null = null;

  private megaWinAnimation: Animation | null = null;

  private greatWinAnimation: Animation | null = null;

  private epicWinAnimation: Animation | null = null;

  private bigWinFadeOutAnimation: Animation | null = null;

  private megaWinFadeOutAnimation: Animation | null = null;

  private greatWinFadeOutAnimation: Animation | null = null;

  private epicWinFadeOutAnimation: Animation | null = null;

  private minorWinFadeOutAnimation: Animation | null = null;

  private majorWinFadeOutAnimation: Animation | null = null;

  private grandWinFadeOutAnimation: Animation | null = null;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.bigWinLabel = this.initBigWin();
    this.megaWinLabel = this.initMegaWin();
    this.greatWinLabel = this.initGreatWin();
    this.epicWinLabel = this.initEpicWin();
    this.minorWinLabel = this.initMinorWin();
    this.majorWinLabel = this.initMajorWin();
    this.grandWinLabel = this.initGrandWin();

    eventManager.addListener(EventTypes.SET_BIG_WIN_VISIBILITY, this.setBigWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_MEGA_WIN_VISIBILITY, this.setMegaWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_GREAT_WIN_VISIBILITY, this.setGreatWinVisibility.bind(this));
    eventManager.addListener(EventTypes.SET_EPIC_WIN_VISIBILITY, this.setEpicWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SET_MINOR_WIN_VISIBILITY, this.setMinorWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SET_MAJOR_WIN_VISIBILITY, this.setMajorWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SET_GRAND_WIN_VISIBILITY, this.setGrandWinVisibility.bind(this));

    eventManager.addListener(EventTypes.SKIP_ALL_WIN_ANIMATIONS, this.skipAllWinAnimations.bind(this));
    eventManager.addListener(EventTypes.HANDLE_START_FADE_ANIMATION, (stage: number) => this.startFade(stage));
    eventManager.addListener(EventTypes.HANDLE_SKIP_FADE_ANIMATION, () => this.skipFadeAnimation());

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private initBigWin(): PIXI.Text {
    const bigWin = new PIXI.Text(BIG_WIN_TITLE, bigWinStyles);
    bigWin.anchor.set(0.5, 0.5);
    bigWin.position.set(WINLABEL_POS_X, WINLABEL_POS_Y);
    bigWin.visible = false;
    this.addChild(bigWin);
    return bigWin;
  }

  private initMegaWin(): PIXI.Text {
    const megaWin = new PIXI.Text(MEGA_WIN_TITLE, megaWinStyles);
    megaWin.anchor.set(0.5, 0.5);
    megaWin.position.set(WINLABEL_POS_X, WINLABEL_POS_Y);
    megaWin.visible = false;
    this.addChild(megaWin);
    return megaWin;
  }

  private initGreatWin(): PIXI.Text {
    const greatWin = new PIXI.Text(GREAT_WIN_TITLE, greatWinStyles);
    greatWin.anchor.set(0.5, 0.5);
    greatWin.position.set(WINLABEL_POS_X, WINLABEL_POS_Y);
    greatWin.visible = false;
    this.addChild(greatWin);
    return greatWin;
  }

  private initEpicWin(): PIXI.Text {
    const epicWin = new PIXI.Text(EPIC_WIN_TITLE, epicWinStyles);
    epicWin.anchor.set(0.5, 0.5);
    epicWin.position.set(WINLABEL_POS_X, WINLABEL_POS_Y);
    epicWin.visible = false;
    this.addChild(epicWin);
    return epicWin;
  }

  private initMinorWin(): Spine {
    const minorWin = new Spine(PIXI.Loader.shared.resources['Message']!.spineData!);
    minorWin.skeleton.setSkinByName('Minor');
    minorWin.position.set(WINLABEL_POS_X, WINLABEL_JACKPOT_POS_Y);
    minorWin.visible = false;
    this.addChild(minorWin);
    return minorWin;
  }

  private initMajorWin(): Spine {
    const majorWin = new Spine(PIXI.Loader.shared.resources['Message']!.spineData!);
    majorWin.skeleton.setSkinByName('Major');
    majorWin.position.set(WINLABEL_POS_X, WINLABEL_JACKPOT_POS_Y);
    majorWin.visible = false;
    this.addChild(majorWin);
    return majorWin;
  }

  private initGrandWin(): Spine {
    const grandWin = new Spine(PIXI.Loader.shared.resources['Message']!.spineData!);
    grandWin.skeleton.setSkinByName('Grand');
    grandWin.position.set(WINLABEL_POS_X, WINLABEL_JACKPOT_POS_Y);
    grandWin.visible = false;
    this.addChild(grandWin);
    return grandWin;
  }

  private setBigWinVisibility(visible: boolean) {
    this.bigWinLabel.visible = visible;
    if (visible) {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = createZoomAnimation(
        this.bigWinLabel,
        BIG_WIN_TITLE_SCALE,
        BIG_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.bigWinAnimation.start();
    } else {
      this.bigWinAnimation?.skip();
      this.bigWinAnimation = null;
    }
  }

  private setMegaWinVisibility(visible: boolean) {
    this.megaWinLabel.visible = visible;
    if (visible) {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = createZoomAnimation(
        this.megaWinLabel,
        MEGA_WIN_TITLE_SCALE,
        MEGA_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.megaWinAnimation.start();
    } else {
      this.megaWinAnimation?.skip();
      this.megaWinAnimation = null;
    }
  }

  private setGreatWinVisibility(visible: boolean) {
    this.greatWinLabel.visible = visible;
    if (visible) {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = createZoomAnimation(
        this.greatWinLabel,
        GREAT_WIN_TITLE_SCALE,
        GREAT_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.greatWinAnimation.start();
    } else {
      this.greatWinAnimation?.skip();
      this.greatWinAnimation = null;
    }
  }

  private setEpicWinVisibility(visible: boolean) {
    this.epicWinLabel.visible = visible;
    if (visible) {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = createZoomAnimation(
        this.epicWinLabel,
        EPIC_WIN_TITLE_SCALE,
        EPIC_WIN_ZOOM_TITLE_DURATION,
        false,
      );
      this.epicWinAnimation.start();
    } else {
      this.epicWinAnimation?.skip();
      this.epicWinAnimation = null;
    }
  }

  private setMinorWinVisibility(visible: boolean) {
    this.minorWinLabel.visible = visible;
    if (visible) {
      this.minorWinLabel.state.setAnimation(0, 'base', true);
    } else {
      this.minorWinLabel.state.setEmptyAnimation(0);
    }
  }

  private setMajorWinVisibility(visible: boolean) {
    this.majorWinLabel.visible = visible;
    if (visible) {
      this.majorWinLabel.state.setAnimation(0, 'base', true);
    } else {
      this.majorWinLabel.state.setEmptyAnimation(0);
    }
  }

  private setGrandWinVisibility(visible: boolean) {
    this.grandWinLabel.visible = visible;
    if (visible) {
      this.grandWinLabel.state.setAnimation(0, 'base', true);
    } else {
      this.grandWinLabel.state.setEmptyAnimation(0);
    }
  }

  private skipAllWinAnimations() {
    this.setBigWinVisibility(false);
    this.setMegaWinVisibility(false);
    this.setGreatWinVisibility(false);
    this.setEpicWinVisibility(false);
    this.setMinorWinVisibility(false);
    this.setMajorWinVisibility(false);
    this.setGrandWinVisibility(false);
  }

  private skipFadeAnimation() {
    this.bigWinFadeOutAnimation?.skip();
    this.megaWinFadeOutAnimation?.skip();
    this.greatWinFadeOutAnimation?.skip();
    this.epicWinFadeOutAnimation?.skip();
    this.minorWinFadeOutAnimation?.skip();
    this.majorWinFadeOutAnimation?.skip();
    this.grandWinFadeOutAnimation?.skip();
  }

  private startFade(stage: WinStages) {
    if (stage === WinStages.BigWin) {
      this.bigWinFadeOutAnimation = this.createFadeAnimation(this.bigWinLabel);
      const onEnd = () => {
        this.bigWinLabel.visible = false;
        this.bigWinLabel.alpha = 1;
        this.bigWinFadeOutAnimation = null;
      };
      this.bigWinFadeOutAnimation.addOnSkip(onEnd);
      this.bigWinFadeOutAnimation.addOnComplete(onEnd);
      this.bigWinFadeOutAnimation.start();
    }

    if (stage === WinStages.MegaWin) {
      this.megaWinFadeOutAnimation = this.createFadeAnimation(this.megaWinLabel);
      const onEnd = () => {
        this.megaWinLabel.visible = false;
        this.megaWinLabel.alpha = 1;
        this.megaWinFadeOutAnimation = null;
      };
      this.megaWinFadeOutAnimation.addOnSkip(onEnd);
      this.megaWinFadeOutAnimation.addOnComplete(onEnd);
      this.megaWinFadeOutAnimation.start();
    }

    if (stage === WinStages.GreatWin) {
      this.greatWinFadeOutAnimation = this.createFadeAnimation(this.greatWinLabel);
      const onEnd = () => {
        this.greatWinLabel.visible = false;
        this.greatWinLabel.alpha = 1;
        this.greatWinFadeOutAnimation = null;
      };
      this.greatWinFadeOutAnimation.addOnSkip(onEnd);
      this.greatWinFadeOutAnimation.addOnComplete(onEnd);
      this.greatWinFadeOutAnimation.start();
    }
    if (stage === WinStages.EpicWin) {
      this.epicWinFadeOutAnimation = this.createFadeAnimation(this.epicWinLabel);
      const onEnd = () => {
        this.epicWinLabel.visible = false;
        this.epicWinLabel.alpha = 1;
        this.epicWinFadeOutAnimation = null;
      };
      this.epicWinFadeOutAnimation.addOnSkip(onEnd);
      this.epicWinFadeOutAnimation.addOnComplete(onEnd);
      this.epicWinFadeOutAnimation.start();
    }
    if (stage === WinStages.MinorWin) {
      this.minorWinFadeOutAnimation = this.createFadeAnimation(this.minorWinLabel);
      const onEnd = () => {
        this.minorWinLabel.visible = false;
        this.minorWinLabel.alpha = 1;
        this.minorWinFadeOutAnimation = null;
      };
      this.minorWinFadeOutAnimation.addOnSkip(onEnd);
      this.minorWinFadeOutAnimation.addOnComplete(onEnd);
      this.minorWinFadeOutAnimation.start();
    }
    if (stage === WinStages.MajorWin) {
      this.majorWinFadeOutAnimation = this.createFadeAnimation(this.majorWinLabel);
      const onEnd = () => {
        this.majorWinLabel.visible = false;
        this.majorWinLabel.alpha = 1;
        this.majorWinFadeOutAnimation = null;
      };
      this.majorWinFadeOutAnimation.addOnSkip(onEnd);
      this.majorWinFadeOutAnimation.addOnComplete(onEnd);
      this.majorWinFadeOutAnimation.start();
    }
    if (stage === WinStages.GrandWin) {
      this.grandWinFadeOutAnimation = this.createFadeAnimation(this.grandWinLabel);
      const onEnd = () => {
        this.grandWinLabel.visible = false;
        this.grandWinLabel.alpha = 1;
        this.grandWinFadeOutAnimation = null;
      };
      this.grandWinFadeOutAnimation.addOnSkip(onEnd);
      this.grandWinFadeOutAnimation.addOnComplete(onEnd);
      this.grandWinFadeOutAnimation.start();
    }
  }

  private createFadeAnimation(target: PIXI.Text | Spine) {
    const fadeOutAnimation = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: target,
      property: TweenProperties.ALPHA,
      // eslint-disable-next-line no-restricted-properties
      easing: (n) => Math.pow(n, 8),
      duration: BIG_WIN_END_DURATION,
    });
    fadeOutAnimation.addOnComplete(() => {
      target.visible = false;
      target.alpha = 1;
    });
    return fadeOutAnimation;
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, _scale: number): void => {
    if (this.window.height > this.window.width) {
      const ADJUST_POS_Y = 330;
      this.bigWinLabel.position.y = WINLABEL_POS_Y + ADJUST_POS_Y;
      this.megaWinLabel.position.y = WINLABEL_POS_Y + ADJUST_POS_Y;
      this.greatWinLabel.position.y = WINLABEL_POS_Y + ADJUST_POS_Y;
      this.epicWinLabel.position.y = WINLABEL_POS_Y + ADJUST_POS_Y;
      this.minorWinLabel.position.y = WINLABEL_JACKPOT_POS_Y + ADJUST_POS_Y;
      this.majorWinLabel.position.y = WINLABEL_JACKPOT_POS_Y + ADJUST_POS_Y;
      this.grandWinLabel.position.y = WINLABEL_JACKPOT_POS_Y + ADJUST_POS_Y;
    } else {
      this.bigWinLabel.position.y = WINLABEL_POS_Y;
      this.megaWinLabel.position.y = WINLABEL_POS_Y;
      this.greatWinLabel.position.y = WINLABEL_POS_Y;
      this.epicWinLabel.position.y = WINLABEL_POS_Y;
      this.minorWinLabel.position.y = WINLABEL_JACKPOT_POS_Y;
      this.majorWinLabel.position.y = WINLABEL_JACKPOT_POS_Y;
      this.grandWinLabel.position.y = WINLABEL_JACKPOT_POS_Y;
    }
  };
}

export default WinLabelContainer;
