import * as PIXI from 'pixi.js';

import { BaseMessageBannerProps, EventTypes } from '../../global.d';
import { setIsOpenedMessageBanner } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import {
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';

import { BANNER_POSITION_X, BANNER_POSITION_Y } from './config';

export class BaseMessageBanner extends ViewContainer {
  protected banner?: PIXI.Sprite;

  protected props: BaseMessageBannerProps;

  protected bindedHandleDestroy = this.handleDestroy.bind(this);

  protected window: PIXI.ISize = { width: 0, height: 0 };

  constructor(props: BaseMessageBannerProps) {
    super();

    this.position.set(BANNER_POSITION_X, BANNER_POSITION_Y);
    this.visible = true;
    this.interactive = true;

    setIsOpenedMessageBanner(true);

    this.props = props;
  }

  protected init(): BaseMessageBanner {
    this.banner = this.initBanner();
    this.addChild(this.banner);

    this.on('click', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
      if (!this.props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);

    if (this.props.onInitCallback) {
      this.props.onInitCallback();
    }

    eventManager.addListener(EventTypes.RESIZE, this.bindedApplicationResize);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.bindedGameContainerResize);
    return this;
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bannerFsTriger));
    banner.anchor.set(0.5);
    return banner;
  }

  public handleDestroy(): void {
    setIsOpenedMessageBanner(false);
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    eventManager.removeListener(EventTypes.RESIZE, this.bindedApplicationResize);
    eventManager.removeListener(EventTypes.RESIZE_GAME_CONTAINER, this.bindedGameContainerResize);

    this.destroy({ children: true });
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
    this.handleResize(width, height);
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(this.window.width, this.window.height);
  };

  public handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
  }
  protected bindedApplicationResize = this.applicationResize.bind(this);

  protected bindedGameContainerResize = this.gameContainerResize.bind(this);
}
