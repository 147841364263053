import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import {
  setBrokenGame,
  setBrokenGameBaseWheel,
  setBrokenGameFreeSpinsWheel,
  setGameMode,
  setIsDuringBigWinLoop,
  setIsDuringWheel,
  setIsFreeSpinsWin,
  setIsShowSoundToast,
  setIsSuspended,
} from '../../gql/cache';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { isFreeSpinsMode } from '../../utils';
import { BgSkin } from '../background/background';

type BgmType = Record<BgmSoundTypes, { base: ISongs; melo?: ISongs }>;

export const bgmList: BgmType = {
  regular: { base: ISongs.XT002S_BGM_BG_Base_Loop },
  fs: { base: ISongs.XT002S_BGM_FS_Loop },
  pachidrop: { base: ISongs.XT002S_BGM_PD_Base_Loop },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes;

  private meloFlg: boolean;

  private timer?: number | undefined;

  constructor() {
    this.bgmListIndex = BgmSoundTypes.BASE;
    this.meloFlg = false;
    this.timer = undefined;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  public get currentBgmType(): BgmSoundTypes {
    return this.bgmListIndex;
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();

      if (this.meloFlg) {
        this.timer = window.setTimeout(() => {
          AudioApi.fadeOut(3000, bgmList[this.bgmListIndex]!.melo!);
        }, 30 * 1000);
      }
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  private onModeChange(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode } = settings;
    const bgmTitle = isFreeSpinsMode(mode) ? BgmSoundTypes.FS : BgmSoundTypes.BASE;

    this.meloFlg = false;

    //pcnc-113
    if (setBrokenGameFreeSpinsWheel()) {
      return;
    }

    if (!this.isPlayBgm(bgmTitle)) {
      this.stopBgm();
      this.playBgm(bgmTitle);
    }
  }

  private setBgmIndex(): void {
    this.bgmListIndex = isFreeSpinsMode(setGameMode()) ? BgmSoundTypes.FS : BgmSoundTypes.BASE;
  }

  public playBgm(bgmListIndex?: BgmSoundTypes, volume?: number, forceResetFade = true): void {
    if (AudioApi.isRestricted) {
      return;
    }
    this.stopBgm();
    if (bgmListIndex === undefined) {
      this.setBgmIndex();
    } else {
      this.bgmListIndex = bgmListIndex;
    }

    const base = bgmList[this.bgmListIndex]!.base;
    if (volume !== undefined) {
      AudioApi.play({ type: base, volume: volume });
    } else {
      AudioApi.play({ type: base });
    }

    /**
     * hack for iOS Safari
     *
     * When the browser loses focus during a fade,
     * and a different bgm is playing in the background,
     * the fade scheduler is NOT cleared
     */
    if (forceResetFade) {
      AudioApi.fadeOut(1, base, 0);
      AudioApi.fadeIn(1, base, audioSpriteVolume[bgmList[this.bgmListIndex]!.base]);
    }

    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioApi.play({
        type: bgmList[this.bgmListIndex]!.melo!,
        volume: 0,
      });
    }
  }

  public stopBgm(): void {
    AudioApi.stop({ type: bgmList[this.bgmListIndex]!.base });
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioApi.stop({
        type: bgmList[this.bgmListIndex]!.melo!,
        volume: 0,
      });
    }
  }

  public isPlayBgm(bgm?: BgmSoundTypes): boolean {
    if (bgm === undefined) {
      return AudioApi.isPlaying(bgmList[this.bgmListIndex]!.base);
    } else {
      return AudioApi.isPlaying(bgmList[bgm]!.base);
    }
  }

  public fadeInBase(fadeTime: number): void {
    AudioApi.fadeIn(fadeTime, bgmList[this.bgmListIndex]!.base, audioSpriteVolume[bgmList[this.bgmListIndex]!.base]);
  }

  public fadeInMelo(fadeTime: number): void {
    if ('melo' in bgmList[this.bgmListIndex]) {
      const soundProp = AudioApi.getSoundByKey(bgmList[this.bgmListIndex]!.melo!);
      if (soundProp.volume !== 0) {
        return;
      }

      this.meloFlg = true;
      AudioApi.fadeIn(
        fadeTime,
        bgmList[this.bgmListIndex]!.melo!,
        audioSpriteVolume[bgmList[this.bgmListIndex]!.melo!],
      );
    }

    this.onSlotMachineStateChange(SlotMachine.getInstance().state);
  }

  public fadeOutAll(fadeTime: number): void {
    AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex]!.base);
    this.fadeOutMelo(fadeTime);
  }

  public fadeOutMelo(fadeTime: number): void {
    if ('melo' in bgmList[this.bgmListIndex]) {
      this.meloFlg = false;
      AudioApi.fadeOut(fadeTime, bgmList[this.bgmListIndex]!.melo!);
    }
  }

  public handleChangeRestriction(): void {
    const playList =
      setBrokenGame() || setBrokenGameBaseWheel() || setIsFreeSpinsWin()
        ? []
        : [
            {
              type: ISongs.XT002S_Openning,
              event: {
                soundKey: ISongs.XT002S_Openning,
                type: 'end',
                callback: () => {
                  if (!setBrokenGameFreeSpinsWheel() && !setBrokenGameBaseWheel()) {
                    if (!this.isPlayBgm()) {
                      this.playBgm(undefined, undefined, false);
                    }
                    //pcnc-118
                    window.requestAnimationFrame(() => {
                      if (!this.isPlayBgm()) {
                        this.playBgm(undefined, undefined, false);
                      }
                    });
                  }
                },
              },
            },
          ];
    setIsSuspended(false);
    AudioApi.unSuspend();
    if (setIsDuringBigWinLoop()) {
      this.setBgmIndex();
      this.playBgm(this.bgmListIndex, 0, false);
      AudioApi.changeRestriction(false, [{ type: ISongs.XT002S_BigWin_Loop }]);
    } else if (setIsDuringWheel()) {
      //pcnc-100
      this.setBgmIndex();
      AudioApi.changeRestriction(false, []);
    } else if (setBrokenGame()) {
      AudioApi.changeRestriction(
        false,
        [],
        () => {},
        () => {
          setIsShowSoundToast(false);
          if (!(setBrokenGameFreeSpinsWheel() || setIsDuringWheel())) {
            if (!this.isPlayBgm()) {
              //least effect prog
              this.playBgm(BgmSoundTypes.FS);
            }
          }
        },
      );
    } else if (isFreeSpinsMode(setGameMode())) {
      AudioApi.changeRestriction(false, []);
      if (!this.isPlayBgm()) {
        this.playBgm();
      }
    } else {
      AudioApi.changeRestriction(false, playList);
    }
  }
}

const bgmControl = new BgmControl();

export { bgmControl as BgmControl };
export default bgmControl;
