export const BANNER_POSITION_X = 0;
export const BANNER_POSITION_Y = -50;

// FS BANNER
export const MESSAGE_FS_BANNER_TITLE_Y = -180;
export const MESSAGE_FS_BANNER_YOU_WON_Y = -90;
export const MESSAGE_FS_BANNER_FEAT_NAME_Y = 0;
export const MESSAGE_FS_BANNER_FIG_POSITIONS = [-261, -83];
export const MESSAGE_FS_BANNER_DESCRIPTION_Y = 90;
export const MESSAGE_FS_BANNER_PRESS_Y = 214;

// WIN BANNER
export const MESSAGE_WIN_BANNER_TITLE_Y = -90;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 0;
export const MESSAGE_WIN_BANNER_BTN_Y = 200;
