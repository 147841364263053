import type TaggedText from 'pixi-tagged-text';

type TextStyleSet = (typeof TaggedText)['defaultStyles'];

export const introStylesInfo: TextStyleSet = {
  default: {
    fontFamily: 'NotoSans-SemiCondensedBold',
    fill: 0xffffff,
    stroke: 0x000000,
    strokeThickness: 0.5,
    fontSize: 36,
    lineJoin: 'bevel',
    fontWeight: 'bold',
    align: 'center',
    dropShadow: false,
    fontStyle: 'normal',
    wordWrapWidth: 1024,
    wordWrapHeight: 512,
    letterSpacing: 0,
  },
  span: {
    fill: 0xff8e00,
    stroke: 0x000000,
  },
  scaleLetter: {
    fontSize: 36,
  },
  title: {
    fill: 0xffcf00,
    stroke: 0x000000,
    strokeThickness: 1,
    fontSize: 36,
    fontWeight: 'bold',
  },
};
