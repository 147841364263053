import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import { formatNumber, normalizeCoins, showCurrency } from '../../utils';
import { SpriteButton } from '../components/button';
import ViewContainer from '../components/container';
import {
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';
import AutoResizeText from '../text/autoResizeText';

import {
  FEATURE_POPUP_BET_TEXT_POSITION,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_X,
  FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_Y,
  FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_X,
  FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_Y,
} from './config';
import {
  betTextStyle,
  betValueStyle,
  buyFeatureTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

export class BuyFeaturePopup extends ViewContainer {
  private popupBg: PIXI.Sprite;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private titleText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostTextAmount: AutoResizeText;

  private betText: AutoResizeText;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private linesAmount: number;

  private currency = 'FUN';

  private betValue: AutoResizeText;

  private balance: number;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.visible = false;
    this.linesAmount = setSlotConfig().lineSet.coinAmountMultiplier;
    this.balance = 0;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.totalCostText = this.initTotalCostText();
    this.totalCostTextAmount = this.initTotalCostTextAmount();
    this.betText = this.initBetText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
  }

  private init(): void {
    this.addChild(
      this.popupBg,
      this.titleText,
      this.totalCostText,
      this.totalCostTextAmount,
      this.betText,
      this.minusBtn,
      this.plusBtn,
      this.input,
      this.betValue,
      this.okBtn,
      this.cancelBtn,
    );
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      this.resetButtons();
      this.handleDisable();
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      this.resetButtons();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => {
      this.visible = false;
      this.resetButtons();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance) => {
      this.balance = balance!.amount / 100;
      this.handleDisable();
    });
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bannerFsBuy1));
    popupBg.anchor.set(0.5, 0.5);

    return popupBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t<string>('buyFeature.title'), buyFeatureTitleStyle);
    title.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.x = FEATURE_POPUP_TITLE_POSITION_X;
    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initBetText(): AutoResizeText {
    const betText = new AutoResizeText(i18n.t<string>('buyFeature.betPerGame'), betTextStyle);
    betText.position.set(...FEATURE_POPUP_BET_TEXT_POSITION);
    betText.anchor.set(0.5, 0.5);

    return betText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnM,
        hover: ResourceTypes.buyFeatureBtnMOver,
        press: ResourceTypes.buyFeatureBtnMClick,
        disable: ResourceTypes.buyFeatureBtnMDisable,
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! > this.betSettings!.minBet) {
          // eslint-disable-next-line no-plusplus
          this.betAmount--;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          eventManager.emit(EventTypes.UPDATE_BET);
          AudioApi.play({ type: ISongs.SFX_UI_BetChange });
        }
      },
    );

    minusBtn.position.set(FEATURE_POPUP_MINUS_BTN_POSITION_X, FEATURE_POPUP_MINUS_BTN_POSITION_Y);
    minusBtn.anchor.set(0.5, 0.5);

    const btnHitArea = {
      w: minusBtn.width * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
      h: minusBtn.height * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
    };
    minusBtn.hitArea = new PIXI.Rectangle(-btnHitArea.w / 2, -btnHitArea.h / 2, btnHitArea.w, btnHitArea.h);

    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnP,
        hover: ResourceTypes.buyFeatureBtnPOver,
        press: ResourceTypes.buyFeatureBtnPClick,
        disable: ResourceTypes.buyFeatureBtnPDisable,
      },
      () => {
        if (this.betSettings.bets[this.betAmount - 1]! < this.betSettings!.maxBet) {
          // eslint-disable-next-line no-plusplus
          this.betAmount++;
          setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
          this.updateBets();
          this.handleDisable();
          setBetAmount(setCoinAmount() * setSlotConfig().lineSet.coinAmountMultiplier);
          eventManager.emit(EventTypes.UPDATE_BET);
          AudioApi.play({ type: ISongs.SFX_UI_BetChange });
        }
      },
    );

    plusBtn.position.set(FEATURE_POPUP_PLUS_BTN_POSITION_X, FEATURE_POPUP_PLUS_BTN_POSITION_Y);
    plusBtn.anchor.set(0.5, 0.5);

    const btnHitArea = {
      w: plusBtn.width * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
      h: plusBtn.height * FEATURE_POPUP_MP_BTN_HITAREA_SCALE,
    };
    plusBtn.hitArea = new PIXI.Rectangle(-btnHitArea.w / 2, -btnHitArea.h / 2, btnHitArea.w, btnHitArea.h);
    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bannerFsBuy1A));
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;

    input.anchor.set(0.5, 0.5);
    input.scale.set(0.8, 1);
    return input;
  }

  private initBetValue(): AutoResizeText {
    const betValue = new AutoResizeText(
      `${formatNumber(this.currency, normalizeCoins(this.getBetValue()), showCurrency(this.currency))}`,
      betValueStyle,
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0.5);

    return betValue;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t<string>('buyFeature.totalCost'), totalCostTextStyle);
    totalCostText.x = FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_X;
    totalCostText.y = FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_Y;
    totalCostText.anchor.set(0.5, 0.5);

    return totalCostText;
  }

  private initTotalCostTextAmount(): AutoResizeText {
    const totalCostTextAmount = new AutoResizeText(this.getTotalCost(), totalCostTextAmountStyle);
    totalCostTextAmount.x = FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_X;
    totalCostTextAmount.y = FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_Y;
    totalCostTextAmount.anchor.set(0.5, 0.5);

    return totalCostTextAmount;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnCancel,
        hover: ResourceTypes.buyFeatureBtnCancelOver,
        press: ResourceTypes.buyFeatureBtnCancelClick,
        disable: ResourceTypes.buyFeatureBtnCancel,
      },
      () => {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        this.visible = false;
        this.resetButtons();
        setIsBuyFeaturePopupOpened(false);
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
        eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      },
    );

    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnOk,
        hover: ResourceTypes.buyFeatureBtnOkOver,
        press: ResourceTypes.buyFeatureBtnOkClick,
        disable: ResourceTypes.buyFeatureBtnOkDisable,
      },
      () => {
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(
          EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
          this.getTotalCost(),
          this.betSettings.bets[this.betAmount]!,
        );
      },
    );

    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5);

    return okBtn;
  }

  private getBetAmount(betAmount: number): number {
    return this.betSettings!.bets.findIndex((bet) => bet === betAmount / this.linesAmount) + 1;
  }

  private updateBets(): void {
    this.totalCostTextAmount.text = this.getTotalCost();
    this.betValue.text = `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue()),
      showCurrency(this.currency),
    )}`;
  }

  private getTotalCost(): string {
    return `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue() * this.getCoinAmount()),
      showCurrency(this.currency),
    )}`;
  }

  private getBetValue(): number {
    return this.linesAmount * (this.betSettings!.bets[this.betAmount - 1] || 1);
  }

  private getCoinAmount(): number {
    const bonuses = setBonuses();
    const bonus = bonuses.filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND)[0] ?? { coinAmount: 0 };

    return bonus.coinAmount;
  }

  public resetButtons(): void {
    this.removeChild(this.minusBtn, this.plusBtn, this.cancelBtn, this.okBtn);
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.addChild(this.minusBtn, this.plusBtn, this.cancelBtn, this.okBtn);
  }

  private handleDisable(): void {
    const bet = this.betSettings.bets[this.betAmount - 1];

    this.minusBtn.disable = bet === this.betSettings!.minBet;

    this.plusBtn.disable = bet === this.betSettings!.maxBet;

    this.okBtn.disable = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount());
  }

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(this.window.width, this.window.height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
  }
}
