import { SlotId } from '../../../config';

import { IAnimateSlot } from './IAnimateSlot';
import { CoinAnimateSlot } from './coinAnimateSlot';
import { ScatterAnimateSlot } from './scatterAnimateSlot';
import { SpineAnimateSlot } from './spineAnimateSlot';

export function animateSlotFactory(slotId: SlotId, num?: number): IAnimateSlot {
  // TODO
  if (num == undefined) num = 0;

  switch (slotId) {
    case SlotId.S:
    case SlotId.G:
      return new CoinAnimateSlot(slotId);
    case SlotId.F:
      return new ScatterAnimateSlot(slotId);
    default:
      return new SpineAnimateSlot(slotId);

    //    return new SpineAnimateSlot(slotId);
  }
}
