import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { easeInOutQuart, isFreeSpinsMode } from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { FLEXIBLE_GAMEVIEW_POSY_RATIO, GameViewLayout, eventManager } from '../config';

class GameTitle extends ViewContainer {
  private title: Spine;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();

    this.title = new Spine(PIXI.Loader.shared.resources['Title_Logo']!.spineData!);

    this.addChild(this.title);
    this.window = { width: 0, height: 0 };

    this.title.state.addAnimation(0, 'base', true);
    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_ZOOM_START, this.startZoomAnimation.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_END, this.onPachiDropEnd.bind(this));
    eventManager.addListener(EventTypes.PACHIDROP_SET_WHEEL_VIEW, this.setPachiDropView.bind(this));

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }) {
    this.visible = !isFreeSpinsMode(settings.mode);
    if (!isFreeSpinsMode(settings.mode)) {
      this.title.alpha = 1.0;
    }
  }

  private makeZoomAnimation(targetObj: Spine, tAlpha: number, duration: number): AnimationGroup {
    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(
      new Tween({
        object: targetObj,
        property: TweenProperties.ALPHA,
        propertyBeginValue: targetObj.alpha,
        target: tAlpha,
        duration: duration,
        easing: easeInOutQuart,
      }),
    );

    return animationGroup;
  }

  private startZoomAnimation(isStart: boolean) {
    if (isStart) {
      this.makeZoomAnimation(this.title, 0, 1000).start();
    } else {
      this.makeZoomAnimation(this.title, 1.0, 1000).start();
    }
  }

  private onPachiDropEnd() {
    this.title.alpha = 1.0;
  }

  private setPachiDropView() {
    this.title.alpha = 0;
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, y: number, scale: number): void => {
    if (this.window.height > this.window.width) {
      this.title.position.set(
        GameViewLayout.Title.width / 2 + GameViewLayout.ReelBase.width / 2 - 90,
        -GameViewLayout.Title.height / 2 + 100 - (y / scale) * FLEXIBLE_GAMEVIEW_POSY_RATIO,
      );
      this.title.scale.set(0.8);
    } else {
      this.title.position.set(
        GameViewLayout.Title.width / 2 + GameViewLayout.Title.left,
        GameViewLayout.Title.height / 2 + GameViewLayout.Title.top,
      );
      this.title.scale.set(1.0);
    }
  };
}

export default GameTitle;
