import { useQuery, useReactiveVar } from '@apollo/client';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Popup } from '@phoenix7dev/shared-components/';
import { cashierUrlAvailable, goToCashier } from '@phoenix7dev/utils-fe';

import {
  setCurrentBonus,
  setIsRevokeThrowingError,
  setIsTimeoutErrorMessage,
  setProgress,
  setStressful,
} from '../../gql/cache';
import { IStressful } from '../../gql/d';
import { stressfulGql } from '../../gql/query';
import { queryParams } from '../../utils';

import styles from './stressful.module.scss';

const getMessageTypeError = (t: TFunction<'translation'>, message: string) => {
  if (message) {
    return message;
  }
  return t('errors.UNKNOWN.UNKNOWN');
};

const defaultStressful: IStressful = {
  show: false,
  type: 'none',
  message: '',
};

const Stressful: React.FC = () => {
  const { data } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { t } = useTranslation();

  const bonusGame = useReactiveVar(setCurrentBonus);
  const isTimeoutError = useReactiveVar(setIsTimeoutErrorMessage);

  const btn = React.useMemo(() => {
    if (data?.stressful.type === 'balance') {
      return (
        cashierUrlAvailable(queryParams) && (
          <button className={`${styles['btn']} btn`} onClick={() => goToCashier(queryParams)}>
            {t('cashier')}
          </button>
        )
      );
    }
    return null;
  }, [data?.stressful, t]);
  if (!data) {
    return null;
  }

  const { message } = data.stressful;

  return (
    <div className={data.stressful.show ? styles['backdrop'] : ''}>
      <Popup id="stressful" className="popup" showNow={data.stressful.show} setShowNow={() => null}>
        <div className={styles['stressful']}>
          <div className={styles['center']}>
            <p className={styles['title']}>{getMessageTypeError(t, message)}</p>
            {btn}
            {!bonusGame.isActive && setProgress().wasLoaded && !isTimeoutError && (
              <button
                className={`${styles['btn']} btn`}
                onClick={() => {
                  setStressful(defaultStressful);
                  setIsRevokeThrowingError(false);
                  //setIsBuyFeaturePurchased(false);
                  data.stressful.callback?.();
                }}
              >
                {t('close')}
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Stressful;
