import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global.d';
import { setBetAmount } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { BASE_WIN_AMOUNT_LIMIT, BIG_WIN_AMOUNT_LIMIT, GREAT_WIN_AMOUNT_LIMIT, MEGA_WIN_AMOUNT_LIMIT } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { MESSAGE_WIN_BANNER_BTN_Y, MESSAGE_WIN_BANNER_TITLE_Y, MESSAGE_WIN_BANNER_WIN_AMOUNT_Y } from './config';
import { freeSpinWinBannerBtnTextStyles, totalWinAmountTextStyles } from './textStyles';

const WinMessageTitleTexture = {
  Base: ResourceTypes.messageWin,
  Big: ResourceTypes.messageBigwin,
  Mega: ResourceTypes.messageMegawin,
  Great: ResourceTypes.messageGreatwin,
  Epic: ResourceTypes.messageEpicwin,
};

export class MessageWinBanner extends BaseMessageBanner {
  protected override props: MessageWinBannerProps;

  private totalWin?: PIXI.Text;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.anchor.set(0.5);
    backdrop.tint = 0x000000;
    backdrop.alpha = 0.3;
    backdrop.width = 5000;
    backdrop.height = 5000;
    this.addChild(backdrop);
  }

  public override init(): MessageWinBanner {
    super.init();

    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.banner?.scale.set(1.0);
    const title = this.initTitle();
    const btn = this.initBtn();
    this.addChild(title, this.totalWin, btn);
    return this;
  }

  protected initTitle(): PIXI.Sprite {
    const winTitle = new PIXI.Sprite(PIXI.Texture.from(this.getTextureName(this.props.totalWinAmount)));
    winTitle.anchor.set(0.5);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y - 100;
    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.updateText(true);
    winTotal.anchor.set(0.5);
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }

  private initBtn(): PIXI.Text {
    const btn = new PIXI.Text(i18n.t<string>('freeSpinsTotalWin.btn'), freeSpinWinBannerBtnTextStyles);
    btn.anchor.set(0.5);
    btn.y = MESSAGE_WIN_BANNER_BTN_Y;

    return btn;
  }

  private getTextureName(win: number): ResourceTypes {
    const multiplier = win / setBetAmount();

    if (multiplier < BASE_WIN_AMOUNT_LIMIT) {
      return WinMessageTitleTexture.Base;
    } else if (multiplier < BIG_WIN_AMOUNT_LIMIT) {
      return WinMessageTitleTexture.Big;
    } else if (multiplier < MEGA_WIN_AMOUNT_LIMIT) {
      return WinMessageTitleTexture.Mega;
    } else if (multiplier < GREAT_WIN_AMOUNT_LIMIT) {
      return WinMessageTitleTexture.Great;
    } else {
      return WinMessageTitleTexture.Epic;
    }
  }
}
