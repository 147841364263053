export const introContents = [
  {
    txtKeyTop: 'introTitle.page1.Top',
    txtKeyBottom: 'introTitle.page1.Bottom',
    imgRelative: { x: 0, y: 0 },
    txtTopRelative: { x: 0, y: 190 },
    txtBottomRelative: { x: 0, y: -280 },
    animation: 'intro_01',
  },
  {
    txtKeyTop: 'introTitle.page2.Top',
    txtKeyBottom: 'introTitle.page2.Bottom',
    imgRelative: { x: 0, y: 0 },
    txtTopRelative: { x: 0, y: 190 },
    txtBottomRelative: { x: 0, y: -280 },
    animation: 'intro_02',
  },
  {
    txtKeyTop: 'introTitle.page3.Top',
    txtKeyBottom: 'introTitle.page3.Bottom',
    imgRelative: { x: 0, y: 0 },
    txtTopRelative: { x: 0, y: 190 },
    txtBottomRelative: { x: 0, y: -270 },
    animation: 'intro_03',
  },
];
