import { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export const ISongs = {
  SFX_UI_AutoSpin: 'SFX_UI_AutoSpin',
  SFX_UI_BetChange: 'SFX_UI_BetChange',
  SFX_UI_Close: 'SFX_UI_Close',
  SFX_UI_General: 'SFX_UI_General',
  SFX_UI_Hover: 'SFX_UI_Hover',
  SFX_UI_MaxBet: 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen: 'SFX_UI_MenuOpen',
  XT002S_Alpaca_High: 'XT002S_Alpaca_High',
  XT002S_Alpaca_Low: 'XT002S_Alpaca_Low',
  XT002S_BGM_BG_Base: 'XT002S_BGM_BG_Base',
  XT002S_BGM_BG_Base_Loop: 'XT002S_BGM_BG_Base_Loop',
  XT002S_BGM_FS_Loop: 'XT002S_BGM_FS_Loop',
  XT002S_BGM_JP_End: 'XT002S_BGM_JP_End',
  XT002S_BGM_JP_Loop: 'XT002S_BGM_JP_Loop',
  XT002S_BGM_Opening_Sun: 'XT002S_BGM_Opening_Sun',
  XT002S_BGM_PD_Base_Loop: 'XT002S_BGM_PD_Base_Loop',
  XT002S_BGM_Wheel_Rotate: 'XT002S_BGM_Wheel_Rotate',
  XT002S_BigWin_End: 'XT002S_BigWin_End',
  XT002S_BigWin_Loop: 'XT002S_BigWin_Loop',
  XT002S_Bonus_1: 'XT002S_Bonus_1',
  XT002S_Coin_Drop_Gold: 'XT002S_Coin_Drop_Gold',
  XT002S_Coin_Drop_Silver: 'XT002S_Coin_Drop_Silver',
  XT002S_Coin_Hit_V: 'XT002S_Coin_Hit_V',
  XT002S_Coin_Hit_pay: 'XT002S_Coin_Hit_pay',
  XT002S_Coin_Lose: 'XT002S_Coin_Lose',
  XT002S_Coin_Pop: 'XT002S_Coin_Pop',
  XT002S_FS_Stop: 'XT002S_FS_Stop',
  XT002S_FeatureTrigger: 'XT002S_FeatureTrigger',
  XT002S_JP: 'XT002S_JP',
  XT002S_Longspin: 'XT002S_Longspin',
  XT002S_Opening_Sun: 'XT002S_Opening_Sun',
  XT002S_Openning: 'XT002S_Openning',
  XT002S_PushBuyFeature: 'XT002S_PushBuyFeature',
  XT002S_SpinStart: 'XT002S_SpinStart',
  XT002S_SpinStart_Loop: 'XT002S_SpinStart_Loop',
  XT002S_SpinStop1: 'XT002S_SpinStop1',
  XT002S_Title: 'XT002S_Title',
  XT002S_Total_Win_Banner_1: 'XT002S_Total_Win_Banner_1',
  XT002S_Total_Win_Banner_2: 'XT002S_Total_Win_Banner_2',
  XT002S_Wheel_Rotate_aori_L: 'XT002S_Wheel_Rotate_aori_L',
  XT002S_Wheel_Rotate_aori_R: 'XT002S_Wheel_Rotate_aori_R',
  XT002S_Wheel_Rotate_com: 'XT002S_Wheel_Rotate_com',
  XT002S_Wheel_Spin_Indicate: 'XT002S_Wheel_Spin_Indicate',
  XT002S_Wheel_Spin_Push: 'XT002S_Wheel_Spin_Push',
  XT002S_Wheel_Stop: 'XT002S_Wheel_Stop',
  XT002S_Win_Big: 'XT002S_Win_Big',
  XT002S_Win_End: 'XT002S_Win_End',
  XT002S_Win_Epic: 'XT002S_Win_Epic',
  XT002S_Win_Great: 'XT002S_Win_Great',
  XT002S_Win_Loop: 'XT002S_Win_Loop',
  XT002S_Win_Mega: 'XT002S_Win_Mega',
  XT002S_Zoom_In: 'XT002S_Zoom_In',
  XT002S_Zoom_Out: 'XT002S_Zoom_Out',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ISongs = (typeof ISongs)[keyof typeof ISongs];

export const audioSprite: AudioSprite = {
  SFX_UI_AutoSpin: [0, 320.4761904761905],
  SFX_UI_BetChange: [2000, 32.086167800453595],
  SFX_UI_Close: [4000, 200.6802721088432],
  SFX_UI_General: [6000, 88.34467120181432],
  SFX_UI_Hover: [8000, 151.76870748299365],
  SFX_UI_MaxBet: [10000, 122.92517006802761],
  SFX_UI_MenuOpen: [12000, 277.14285714285757],
  XT002S_Alpaca_High: [14000, 3000],
  XT002S_Alpaca_Low: [18000, 3000],
  XT002S_BGM_BG_Base: [22000, 48000],
  XT002S_BGM_BG_Base_Loop: [71000, 48000, true],
  XT002S_BGM_FS_Loop: [120000, 25600.022675736967, true],
  XT002S_BGM_JP_End: [147000, 3428.594104308388],
  XT002S_BGM_JP_Loop: [152000, 13714.331065759638, true],
  XT002S_BGM_Opening_Sun: [167000, 7000],
  XT002S_BGM_PD_Base_Loop: [175000, 12800.022675736955, true],
  XT002S_BGM_Wheel_Rotate: [189000, 11415.827664399103],
  XT002S_BigWin_End: [202000, 3000],
  XT002S_BigWin_Loop: [206000, 12000, true],
  XT002S_Bonus_1: [219000, 1000],
  XT002S_Coin_Drop_Gold: [221000, 1500],
  XT002S_Coin_Drop_Silver: [224000, 1500],
  XT002S_Coin_Hit_V: [227000, 2000],
  XT002S_Coin_Hit_pay: [230000, 1500.0226757369717],
  XT002S_Coin_Lose: [233000, 1000],
  XT002S_Coin_Pop: [235000, 1089.4104308389956],
  XT002S_FS_Stop: [238000, 666.6666666666572],
  XT002S_FeatureTrigger: [240000, 6000],
  XT002S_JP: [247000, 2000],
  XT002S_Longspin: [250000, 4033.333333333331],
  XT002S_Opening_Sun: [256000, 6000],
  XT002S_Openning: [263000, 12000],
  XT002S_PushBuyFeature: [276000, 633.3333333333258],
  XT002S_SpinStart: [278000, 503.3333333333303],
  XT002S_SpinStart_Loop: [280000, 1611.5646258503489, true],
  XT002S_SpinStop1: [283000, 333.3560090703145],
  XT002S_Title: [285000, 2016.6666666666515],
  XT002S_Total_Win_Banner_1: [289000, 6000],
  XT002S_Total_Win_Banner_2: [296000, 8000],
  XT002S_Wheel_Rotate_aori_L: [305000, 1000.0226757369433],
  XT002S_Wheel_Rotate_aori_R: [308000, 522.0861678004667],
  XT002S_Wheel_Rotate_com: [310000, 8000],
  XT002S_Wheel_Spin_Indicate: [319000, 1000],
  XT002S_Wheel_Spin_Push: [321000, 1000],
  XT002S_Wheel_Stop: [323000, 2000],
  XT002S_Win_Big: [326000, 1800.0453514738979],
  XT002S_Win_End: [329000, 1000],
  XT002S_Win_Epic: [331000, 3000.0453514739434],
  XT002S_Win_Great: [336000, 1800.0453514738979],
  XT002S_Win_Loop: [339000, 1000, true],
  XT002S_Win_Mega: [341000, 1800.0453514738979],
  XT002S_Zoom_In: [344000, 1500],
  XT002S_Zoom_Out: [347000, 2500],
};
