import { AnimationStateListener, Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { MAPPED_SPINE_SYMBOLS, SlotId } from '../../../config';
import Animation from '../../animations/animation';
import { SLOT_SCALE } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

export class SpineAnimateSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private spine: Spine;

  constructor(slotId: SlotId) {
    super();

    this.slotId = slotId;
    this.scale.set(SLOT_SCALE);

    this.spine = new Spine(PIXI.Loader.shared.resources['symbol']!.spineData!);
    this.spine.visible = true;
    this.spine.state.setAnimation(0, `${MAPPED_SPINE_SYMBOLS[slotId]}_base`, true);
    this.addChild(this.spine);
  }

  public startStopAnimation(): void {
    this.spine.state.setAnimation(0, `${MAPPED_SPINE_SYMBOLS[this.slotId]}_stop`, false);
  }

  public startWinAnimation(): void {
    this.spine.state.setAnimation(0, `${MAPPED_SPINE_SYMBOLS[this.slotId]}_win`, false);
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration =
      (this.spine.skeleton.data.findAnimation(`${MAPPED_SPINE_SYMBOLS[this.slotId]}_win`)?.duration ?? 0) * 1000;

    const listener: AnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = 10;
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });
    animation.addOnSkip(() => {
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public skip(): void {
    this.spine.state.setAnimation(0, `${MAPPED_SPINE_SYMBOLS[this.slotId]}_base`, true);
  }

  public set tint(value: number) {
    this.spine.tint = value;
  }
}
