import { PachiDropPrizeType } from '../config';

import PachiDropPrize from './pachiDropPrize';
import PachiDropPrizeAltar from './pachiDropPrizeAltar';
import { IPachiDropPrizeBase } from './pachiDropPrizeBase';

export function pachiDropPrizeFactory(type: PachiDropPrizeType): IPachiDropPrizeBase {
  switch (type) {
    case 'v_bs':
    case 'v_fs':
      return new PachiDropPrizeAltar(type);
    default:
      return new PachiDropPrize(type);

    //    return new SpineAnimateSlot(slotId);
  }
}
