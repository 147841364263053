import * as PIXI from 'pixi.js';

import { EventTypes, Events } from '../../global.d';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { GameViewLayout, REELBASE_SCALE, eventManager } from '../config';

class Backdrop extends PIXI.Sprite {
  private windowSize: PIXI.ISize;

  constructor(openOn?: keyof Events, closeOn?: keyof Events, fadeDuration?: number) {
    super(PIXI.Texture.WHITE);

    this.position.set(GameViewLayout.ReelBase.width / 2, GameViewLayout.ReelBase.height / 2);
    this.anchor.set(0.5);

    this.tint = 0x000000;
    this.alpha = 0.5;
    this.interactive = true;
    this.visible = false;

    this.windowSize = { width: 1, height: 1 };
    eventManager.addListener(EventTypes.RESIZE, (width, height) => {
      this.windowSize = { width, height };
      this.windowSize.width *= REELBASE_SCALE * 2;
      this.windowSize.height *= REELBASE_SCALE * 2;
    });
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, (_width, _height, _x, _y, scale) => {
      this.width = this.windowSize.width / scale;
      this.height = this.windowSize.height / scale;
    });

    if (openOn) {
      eventManager.on(openOn, () => {
        this.visible = true;
        if (fadeDuration) {
          this.getFadeAnimation(0.7, fadeDuration, 0).start();
        }
      });
    }
    if (closeOn) {
      eventManager.on(closeOn, () => {
        this.visible = false;
      });
    }
  }

  private getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }
}
export default Backdrop;
