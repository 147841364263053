import { Event, Spine, TrackEntry } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../../config';
import { EventTypes } from '../../../global.d';
import { eventManager } from '../../config';
import { IPachiDropPrizeSpine, PACHIDROP_PRIZE_SPINE, PachiDropCoinType, PachiDropPrizeType } from '../config';

import { IPachiDropPrizeBase } from './pachiDropPrizeBase';

class PachiDropPrizeAltar extends PIXI.Container implements IPachiDropPrizeBase {
  private spine: Spine;

  public type: PachiDropPrizeType;

  private spineInfo: IPachiDropPrizeSpine;

  private coinCount: number;

  constructor(type: PachiDropPrizeType) {
    super();
    this.type = type;
    this.coinCount = 0;

    this.spineInfo = PACHIDROP_PRIZE_SPINE[type];
    this.spine = new Spine(PIXI.Loader.shared.resources[PACHIDROP_PRIZE_SPINE[type]!.spineName]!.spineData!);
    this.addChild(this.spine);

    this.spine.state.setAnimation(0, this.spineInfo.animName);
    this.spine.skeleton.setSkinByName(this.spineInfo.coinSkin!);
    this.spine.state.addAnimation(0, this.spineInfo.loopAnim!, true);

    this.spine.state.addListener({
      event: (_entry: TrackEntry, _event: Event) => {},
      complete: (__entry: TrackEntry) => {},
    });

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_SPIN_START, this.decCoinCnt.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SHOW_WHEEL, this.stopLoopAnimation.bind(this));
  }

  public reset(): void {
    this.coinCount = 0;
    //    this.spine.state.setAnimation(0, this.spineInfo.animName);
    //    this.spine.skeleton.setSkinByName(this.spineInfo.coinSkin!);
    //    this.spine.state.addAnimation(0, this.spineInfo.loopAnim!, true);
  }

  public startCoinInAnimation(coinType: PachiDropCoinType) {
    this.spine.state.setAnimation(0, this.spineInfo.inAnim01);
    this.spine.state.addAnimation(0, this.spineInfo.loopAnim!, true);
    this.coinCount += 1;

    eventManager.emit(EventTypes.PACHIDROP_WHEEL_COIN_IN_EFFECT, coinType);
    eventManager.emit(EventTypes.PACHIDROP_COIN_IN_ALTER, coinType);
    eventManager.emit(EventTypes.PACHIDROP_NEXT_DROP);

    AudioApi.play({ type: ISongs.XT002S_Coin_Hit_V });
  }

  public stopLoopAnimation() {
    this.spine.state.setAnimation(0, this.spineInfo.animName);
  }

  public decCoinCnt() {
    if (this.coinCount) {
      this.coinCount -= 1;
    }
  }

  public setSkin(_: string): void {}

  public setType(_: PachiDropPrizeType): void {}

  public skip() {}
}

export default PachiDropPrizeAltar;
