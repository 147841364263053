import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setIsLeftHandMode } from '../../gql/cache';
import { easeInSine, easeOutQuart, isMobileDevice } from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { DURATION_BASE_TO_PACHIDROP, DURATION_PACHIDROP_TO_BASE, eventManager } from '../config';

import { PachiDropCoinType } from './config';
import PachiDropCoinCounter from './pachiDropCoinCounter';
import PachiDropFreeSpinCounter from './pachiDropFreeSpinCounter';

class PachiDropUIContainer extends PIXI.Container {
  private gc: PachiDropCoinCounter;

  private sc: PachiDropCoinCounter;

  private fs: PachiDropFreeSpinCounter;

  private isLeftHandMode: boolean;

  private window: PIXI.ISize = { width: 0, height: 0 };

  private gameViewScale: number;

  private gameViewSize: PIXI.ISize = { width: 0, height: 0 };

  private safeAreaPos: PIXI.IPointData = { x: 0, y: 0 };

  constructor() {
    super();

    this.gc = new PachiDropCoinCounter('gold');
    this.sc = new PachiDropCoinCounter('silver');
    this.fs = new PachiDropFreeSpinCounter();

    this.window = { width: 0, height: 0 };
    this.gameViewScale = 1;

    this.isLeftHandMode = setIsLeftHandMode();

    this.init();
    this.setAllVisible(false);

    this.addChild(this.gc);
    this.addChild(this.sc);
    this.addChild(this.fs);

    eventManager.addListener(EventTypes.PACHIDROP_COIN_IN_ALTER, this.incCoin.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_SPIN_START, this.onWheelSpinStart.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WHEEL_SPIN_STOP, this.onWheelSpinStop.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_COIN_CNT_VISIBLE, this.setCoinVisible.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_COIN_CNT, this.setCoin.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_FREESPIN_CNT_VISIBLE, this.setFreeSpinCntVisible.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SET_FREESPIN_CNT, this.setFreeSpinsCnt.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_UPD_FREESPIN_CNT, this.updFreeSpinsCnt.bind(this));

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));

    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));

    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
  }

  private init() {
    /*
    this.fs.position.set(0, 0);
    this.gc.position.set(this.fs.width - this.gc.width, this.fs.height + 100);
    this.sc.position.set(this.fs.width - this.gc.width, this.gc.position.y + this.gc.height + 20);
*/
    this.gc.position.set(this.fs.width - this.gc.width, 40);
    this.sc.position.set(this.fs.width - this.gc.width, this.gc.position.y + this.gc.height + 20);
    this.fs.position.set(0, this.sc.position.y + this.sc.height / 2 + 20);

    this.scale.set(0.5);

    this.handlePosition();
  }

  public onPachiDropViewStart() {
    this.setCoinVisible(true);
    const animGroup = new AnimationGroup();
    animGroup.addAnimation(
      new Tween({
        object: this.gc,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 0,
        target: 1,
        duration: DURATION_BASE_TO_PACHIDROP - 500,
        easing: easeInSine,
        delay: 500,
      }),
    );
    animGroup.addAnimation(
      new Tween({
        object: this.sc,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 0,
        target: 1,
        duration: DURATION_BASE_TO_PACHIDROP - 500,
        easing: easeInSine,
        delay: 500,
      }),
    );
    animGroup.start();
  }

  public onPachiDropViewEnd() {
    const animGroup = new AnimationGroup();
    animGroup.addAnimation(
      new Tween({
        object: this.gc,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 1,
        target: 0,
        duration: DURATION_PACHIDROP_TO_BASE,
        easing: easeOutQuart,
      }),
    );
    animGroup.addAnimation(
      new Tween({
        object: this.sc,
        property: TweenProperties.ALPHA,
        propertyBeginValue: 1,
        target: 0,
        duration: DURATION_PACHIDROP_TO_BASE,
        easing: easeOutQuart,
      }),
    );
    animGroup.start();
    animGroup.addOnComplete(() => {
      this.setCoinVisible(false);
    });
  }

  private onWheelSpinStart() {
    this.decCoinAuto();
  }

  private onWheelSpinStop() {
    this.sc.stopCoinEffect();
    this.gc.stopCoinEffect();
  }

  private setCoin(sc: number, gc: number) {
    this.sc.setCnt(sc);
    this.gc.setCnt(gc);
  }

  private incCoin(coinType: PachiDropCoinType) {
    if (coinType === 'GoldCoin') {
      this.gc.incCoin();
    }
    if (coinType === 'SilverCoin') {
      this.sc.incCoin();
    }
  }

  private decCoinAuto() {
    if (this.sc.getCnt()) {
      this.decCoin('SilverCoin');
      this.sc.startCoinEffect();
    } else {
      this.decCoin('GoldCoin');
      this.gc.startCoinEffect();
    }
  }

  private decCoin(coinType: PachiDropCoinType) {
    if (coinType === 'GoldCoin') this.gc.decCoin();
    if (coinType === 'SilverCoin') this.sc.decCoin();
  }

  private setFreeSpinsCnt(cnt: number) {
    this.fs.setCnt(cnt);
  }

  private updFreeSpinsCnt(cnt: number) {
    this.fs.updateCnt(cnt);
  }

  private reset() {
    this.gc.reset();
    this.sc.reset();
    this.fs.reset();
  }

  private setFreeSpinCntVisible(flg: boolean) {
    this.fs.visible = flg;
    this.handlePosition();
  }

  private setCoinVisible(flg: boolean) {
    this.gc.visible = flg;
    this.sc.visible = flg;

    if (flg) {
      this.gc.alpha = 1;
      this.sc.alpha = 1;
    }
    this.handlePosition();
  }

  private setAllVisible(flg: boolean) {
    this.setFreeSpinCntVisible(flg);
    this.setCoinVisible(flg);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (width: number, height: number, x: number, y: number, scale: number): void => {
    this.gameViewSize = { width, height };
    this.gameViewScale = scale;
    this.safeAreaPos = { x, y };
    this.handlePosition();
  };

  private handlePosition(): void {
    let positionX = 0;
    let positionY = 270;
    const coinBasePos = { x: 0, y: 0 };

    if (this.window.height > this.window.width) {
      positionX = 1020 + (this.safeAreaPos.x / this.gameViewScale) * 0.5;
      positionY = -350 - (this.safeAreaPos.y / this.gameViewScale) * 0.5;
      coinBasePos.x = this.fs.width - this.gc.width;
    } else {
      if (isMobileDevice()) {
        positionX = this.isLeftHandMode
          ? -100 - (this.safeAreaPos.x / this.gameViewScale) * 0.5
          : 1020 + (this.safeAreaPos.x / this.gameViewScale) * 0.5;
      } else {
        positionX = this.isLeftHandMode
          ? -100 - (this.safeAreaPos.x / this.gameViewScale) * 0.7
          : 1250 + (this.safeAreaPos.x / this.gameViewScale) * 0.7;
      }
      positionY = this.isLeftHandMode ? -260 : -260;

      coinBasePos.x = this.isLeftHandMode ? 0 : this.fs.width - this.gc.width;
    }
    this.position.set(positionX, positionY);

    //    coinBasePos.y = this.fs.visible ? this.fs.height : 0;
    //    this.gc.position.set(coinBasePos.x, coinBasePos.y + 100);
    //    this.sc.position.set(coinBasePos.x, this.gc.position.y + this.gc.height + 20);
  }
}

export default PachiDropUIContainer;
