import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.symbolSc,
      combos: [{ pattern: 'x3', multiplier: 40 }],
    },
    {
      slug: ResourceTypes.infoSymbolCoin,
      combos: [],
    },
    {
      slug: ResourceTypes.symbolA,
      combos: [
        { pattern: 'x1', multiplier: 10 },
        { pattern: 'x2', multiplier: 60 },
        { pattern: 'x3', multiplier: 200 },
      ],
    },
    {
      slug: ResourceTypes.symbolB,
      combos: [
        { pattern: 'x1', multiplier: 10 },
        { pattern: 'x2', multiplier: 60 },
        { pattern: 'x3', multiplier: 200 },
      ],
    },
    {
      slug: ResourceTypes.symbolC,
      combos: [
        { pattern: 'x1', multiplier: 10 },
        { pattern: 'x2', multiplier: 60 },
        { pattern: 'x3', multiplier: 200 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  B = 'B',
  C = 'C',
  S = 'S',
  G = 'G',
  F = 'F',
  X = 'X',
}

export enum ReelStopSndType {
  Normal,
  Scatter1,
  Scatter2,
  Scatter3,
}

export const SC_REELS = [0, 1, 2];
export const ANTICIPATION_START_REELID = 1;
export const ANTICIPATION_START_SCCNT = 2;
