import { Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { pachiDropCoinCntStyles } from '../config';

class PachiDropCoinCounter extends PIXI.Container {
  private spine: Spine;

  private text: PIXI.Text;

  private cnt: number;

  constructor(skinName: string) {
    super();

    this.cnt = 0;

    this.spine = new Spine(PIXI.Loader.shared.resources['Wheel_count_icon']!.spineData!);
    this.spine.skeleton.setSkinByName(skinName);
    this.spine.state.setAnimation(0, 'base');
    this.spine.pivot.set(-this.spine.width / 2, 0);
    this.spine.scale.set(2);

    this.text = new PIXI.Text(`x${this.cnt}`, pachiDropCoinCntStyles);
    this.text.anchor.set(1, 0.5);
    this.text.position.set(230 + this.text.width, this.text.height / 2);

    this.addChild(this.spine);
    this.addChild(this.text);
  }

  private init() {}

  public setCnt(num: number) {
    this.cnt = num;
    this.updateText();
  }

  public incCoin() {
    this.cnt += 1;
    this.updateText();
    this.createUpdateTextAnimation(2, 800);
  }

  public decCoin() {
    if (this.cnt) this.cnt -= 1;
    this.updateText();
    this.createUpdateTextAnimation(1.3, 400);
  }

  public startCoinEffect() {
    this.spine.state.setAnimation(0, 'active', true);
  }

  public stopCoinEffect() {
    this.spine.state.setAnimation(0, 'base');
  }

  private updateText() {
    this.text.text = `x${this.cnt}`;
  }

  public reset() {
    this.cnt = 0;
    this.stopCoinEffect();
  }

  public getCnt(): number {
    return this.cnt;
  }

  private createUpdateTextAnimation(target_scale: number, duration: number) {
    const animationGroup = new AnimationGroup();
    animationGroup.addAnimation(
      new Tween({
        object: this.text.scale,
        property: TweenProperties.X,
        propertyBeginValue: target_scale,
        target: 1,
        duration: duration,
        easing: (x) => {
          return -Math.cos(x * Math.PI) * 0.5 + 0.5;
        },
      }),
    );
    animationGroup.addAnimation(
      new Tween({
        object: this.text.scale,
        property: TweenProperties.Y,
        propertyBeginValue: target_scale,
        target: 1,
        duration: duration,
        easing: (x) => {
          return -Math.cos(x * Math.PI) * 0.5 + 0.5;
        },
      }),
    );
    animationGroup.start();
  }
}

export default PachiDropCoinCounter;
