import { AnimationStateListener, Spine } from '@pixi-spine/all-4.1';
import * as PIXI from 'pixi.js';

import { SlotId } from '../../../config';
import Animation from '../../animations/animation';
import { SLOT_SCALE } from '../../config';

import { IAnimateSlot } from './IAnimateSlot';

export class ScatterAnimateSlot extends PIXI.Container implements IAnimateSlot {
  public slotId: SlotId;

  private spine: Spine;

  private reelRow: number;

  constructor(slotId: SlotId) {
    super();

    this.slotId = slotId;
    this.reelRow = 0;
    this.scale.set(SLOT_SCALE);

    this.spine = new Spine(PIXI.Loader.shared.resources['symbol']!.spineData!);

    this.spine.state.setAnimation(0, 'SC_base', true);
    this.spine.visible = true;

    this.spine.state.addListener({
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {}, PIXI.UPDATE_PRIORITY.INTERACTION);
      },
    });

    this.addChild(this.spine);
  }

  public startStopAnimation(): void {
    //    this.spine.state.setAnimation(0, 'SC_win', false);
    this.spine.state.addAnimation(0, 'SC_base', true, 0);
  }

  public startReachStopAnimation(): void {
    //    this.spine.state.setAnimation(0, 'SC_win', false);
    this.spine.state.addAnimation(0, 'SC_base', true, 0);
  }

  public endReachStopAnimation(): void {
    //  this.spine.state.addAnimation(0, 'SC_win', true, 0);
  }

  public startWinAnimation(): void {
    const trackEntry = this.spine.state.getCurrent(0);
    if (trackEntry && trackEntry.animation?.name == 'SC_win') return;
    this.spine.state.setAnimation(0, 'SC_win', true);
    //    this.spine.state.addAnimation(0, 'SC_base', true, 0);
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = (this.spine.skeleton.data.findAnimation('SC_win')?.duration ?? 0) * 1000;

    const listener: AnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = 10;
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });
    animation.addOnSkip(() => {
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public skip(): void {}

  public set tint(value: number) {
    this.spine.tint = value;
  }
}
export const isScatterAnimateSlot = (symbol: IAnimateSlot): symbol is ScatterAnimateSlot => {
  return symbol.slotId == SlotId.F;
};
