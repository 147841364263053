import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { SpriteButton } from '../components/button';
import {
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_PIVOT_X,
  SAFE_AREA_LANDSCAPE_PIVOT_Y,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SAFE_AREA_PORTRAIT_HEIGHT,
  SAFE_AREA_PORTRAIT_PIVOT_X,
  SAFE_AREA_PORTRAIT_PIVOT_Y,
  SAFE_AREA_PORTRAIT_WIDTH,
  eventManager,
} from '../config';
import AutoResizeText from '../text/autoResizeText';

import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION,
  FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
} from './config';
import {
  buyFeatureConfirmTextStyle,
  buyFeatureConfirmTitleStyle,
  totalCostTextAmountStyle,
  totalCostTextStyle,
} from './textStyles';

export class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private popupBlackBoard: PIXI.Sprite;

  private popupConfirmBg: PIXI.Sprite;

  private titleText: AutoResizeText;

  private confirmText: AutoResizeText;

  private totalCostText: AutoResizeText;

  private totalCostValue: AutoResizeText;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private window: PIXI.ISize = { width: 0, height: 0 };

  constructor() {
    super();
    this.visible = false;
    this.popupBg = this.initPopupBg();
    this.popupBlackBoard = this.initPopupBlackBoard();
    this.popupConfirmBg = this.initPopupConfirmBg();
    this.titleText = this.initTitle();
    this.confirmText = this.initConfirmText();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.popupBlackBoard);
    this.addChild(this.popupConfirmBg);
    this.addChild(this.titleText);
    this.addChild(this.confirmText);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose());
    eventManager.on(EventTypes.BUY_FEATURE_CONFIRMED, () => this.handleClose());
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.handleOpen(totalCost, coinAmount);
    });
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.gameContainerResize.bind(this));
    eventManager.on(EventTypes.RESIZE, this.applicationResize.bind(this));
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bannerFsBuy1));
    popupBg.anchor.set(0.5);

    return popupBg;
  }

  private initPopupBlackBoard(): PIXI.Sprite {
    const popupBlackBoard = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bannerFsBuy1));
    popupBlackBoard.anchor.set(0.5);

    return popupBlackBoard;
  }

  private initPopupConfirmBg(): PIXI.Sprite {
    const popupConfirmBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.bannerFsBuy1));
    popupConfirmBg.anchor.set(0.5);

    return popupConfirmBg;
  }

  private initTitle(): AutoResizeText {
    const title = new AutoResizeText(i18n.t<string>('buyFeature.confirm.title'), buyFeatureConfirmTitleStyle);
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;

    title.anchor.set(0.5, 0.5);

    return title;
  }

  private initConfirmText(): AutoResizeText {
    const text = new AutoResizeText(i18n.t<string>('buyFeature.confirm.text'), buyFeatureConfirmTextStyle);
    text.y = FEATURE_POPUP_CONFIRM_TEXT_POSITION_Y;

    text.anchor.set(0.5, 0.5);

    return text;
  }

  private initTotalCostText(): AutoResizeText {
    const totalCostText = new AutoResizeText(i18n.t<string>('buyFeature.totalCost'), totalCostTextStyle);
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;

    totalCostText.anchor.set(0.5, 0.5);

    return totalCostText;
  }

  private initTotalCostValue(): AutoResizeText {
    const totalCostValue = new AutoResizeText('0', totalCostTextAmountStyle);
    totalCostValue.anchor.set(0.5, 0.5);
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;

    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnCancel,
        hover: ResourceTypes.buyFeatureBtnCancelOver,
        press: ResourceTypes.buyFeatureBtnCancelClick,
        disable: ResourceTypes.buyFeatureBtnCancel,
      },
      () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioApi.play({ type: ISongs.SFX_UI_Close });
      },
    );
    cancelBtn.position.set(...FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION);
    cancelBtn.anchor.set(0.5);

    return cancelBtn;
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton(
      {
        default: ResourceTypes.buyFeatureBtnOk,
        hover: ResourceTypes.buyFeatureBtnOkOver,
        press: ResourceTypes.buyFeatureBtnOkClick,
        disable: ResourceTypes.buyFeatureBtnOkDisable,
      },
      () => {
        this.okBtn.interactive = false;
        this.cancelBtn.interactive = false;
        AudioApi.play({ type: ISongs.SFX_UI_General });
        eventManager.emit(EventTypes.HANDLE_BUY_BONUS);
      },
    );
    okBtn.position.set(...FEATURE_POPUP_CONFIRM_OK_BTN_POSITION);
    okBtn.anchor.set(0.5);

    return okBtn;
  }

  public handleOpen(totalCost: string, _coinAmount: number): void {
    this.visible = true;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
    this.totalCostValue.text = totalCost;
  }

  public handleClose(): void {
    this.visible = false;
    this.okBtn._trackedPointers = {};
    this.cancelBtn._trackedPointers = {};
    this.resetButtons();
  }

  public resetButtons(): void {
    this.removeChild(this.cancelBtn, this.okBtn);
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.addChild(this.cancelBtn, this.okBtn);
  }

  private applicationResize = (width: number, height: number): void => {
    this.window = { width, height };
  };

  private gameContainerResize = (_width: number, _height: number, _x: number, _y: number, _scale: number): void => {
    this.handleResize(this.window.width, this.window.height);
  };

  private handleResize(width: number, height: number): void {
    if (height > width) {
      this.x = SAFE_AREA_PORTRAIT_WIDTH / 2 + SAFE_AREA_PORTRAIT_PIVOT_X;
      this.y = SAFE_AREA_PORTRAIT_HEIGHT / 2 + SAFE_AREA_PORTRAIT_PIVOT_Y;
    } else {
      this.x = SAFE_AREA_LANDSCAPE_WIDTH / 2 + SAFE_AREA_LANDSCAPE_PIVOT_X;
      this.y = SAFE_AREA_LANDSCAPE_HEIGHT / 2 + SAFE_AREA_LANDSCAPE_PIVOT_Y;
    }
  }
}
