import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, GameMode, UserBonus, reelSets } from '../../global.d';
import {
  setBonuses,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setIsFreeSpinsWin,
  setLastRegularWinAmount,
} from '../../gql/cache';
import { buyBonusGql } from '../../gql/mutation';
import { BgmControl } from '../../slotMachine/bgmControl/bgmControl';
import { FREE_SPINS_FADE_IN_DURATION, FREE_SPINS_FADE_OUT_DURATION, eventManager } from '../../slotMachine/config';
import { resetWheelBonus } from '../../utils';

const useBuyFeature = () => {
  const [buyBonus] = useMutation(buyBonusGql, {
    onCompleted(data: { buyBonus: UserBonus }) {
      eventManager.emit(EventTypes.BUY_FEATURE_CONFIRMED);

      setLastRegularWinAmount(0);
      setCurrentFreeSpinsTotalWin(0);
      setCurrentBonus({
        ...data.buyBonus,
        isActive: true,
        currentRound: data.buyBonus.roundsPlayed,
        totalRounds: data.buyBonus.rounds + data.buyBonus.roundsPlayed,
      });
      resetWheelBonus();
      setIsFreeSpinsWin(true);
      AudioApi.stop({ type: ISongs.XT002S_Openning });
      BgmControl.fadeOutAll(1000);
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, data.buyBonus.balance);
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.FREE_SPINS,
        reelPositions: [0, 0, 0],
        reelSetId: reelSets[GameMode.FREE_SPINS]!,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
      });
    },
  });

  useEffect(() => {
    const bonuses = setBonuses();
    const bonus = bonuses.filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND)[0];
    const handleBuyBonus = () => {
      buyBonus({
        variables: {
          input: {
            id: bonus!.id,
            coinValue: setCoinValue(),
            coinAmount: setCoinAmount(),
          },
        },
      });
    };

    eventManager.on(EventTypes.HANDLE_BUY_BONUS, handleBuyBonus);
    return () => {
      eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS, handleBuyBonus);
    };
  }, [buyBonus]);
};

export default useBuyFeature;
