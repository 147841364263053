import { AutoResizeTextStyle } from '../text/autoResizeText';

const font = 'NotoSans-SemiCondensedBold';

export const buyFeatureTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 56,
  fontFamily: font,
  maxWidth: 600,
  fill: ['#fd0', '#ffe100', '#e08a00', '#fd0'],
  fontWeight: 'bold',
  lineJoin: 'round',
  strokeThickness: 3,
};
export const buyFeatureConfirmTitleStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 42,
  fontFamily: font,
  fill: '#ffffff',
  fontWeight: 'bold',
  align: 'center',
  maxWidth: 600,
};

export const buyFeatureConfirmTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 52,
  fontFamily: font,
  fill: '#00d8ff',
  fontWeight: 'bold',
  align: 'center',
  maxWidth: 600,
};

export const totalCostTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 43.5,
  fontFamily: font,
  align: 'center',
  maxWidth: 600,
  fill: ['#bdf4b1', '#00bd3c'],
  fontWeight: 'bold',
  lineJoin: 'round',
  strokeThickness: 3,
};

export const totalCostTextAmountStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 70,
  fontFamily: font,
  fill: '#ffff00',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

export const betTextStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 48,
  fontFamily: font,
  align: 'center',
  fontStyle: 'normal',
  maxWidth: 600,
  fill: ['#bdf4b1', '#00bd3c'],
  fontWeight: 'bold',
  lineJoin: 'round',
  strokeThickness: 3,
};

export const betValueStyle: Partial<AutoResizeTextStyle> = {
  fontSize: 47.5,
  fontFamily: font,
  fill: '#ffff00',
  fontWeight: '500',
  align: 'center',
  maxWidth: 600,
};

// Button Text Style
export const buyFeatureTextStyle: Partial<AutoResizeTextStyle> = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 36,
  lineHeight: 36,
  stroke: '#000000',
  maxWidth: 140,
  maxHeight: 100,
  dropShadow: true,
  dropShadowAlpha: 0.7,
  dropShadowAngle: 0.6,
  fill: '#ffc900',
  strokeThickness: 4,
};

export const buyFeatureHoverTextStyle: Partial<AutoResizeTextStyle> = {
  ...buyFeatureTextStyle,
  fill: '#ffe380',
  stroke: '#945600',
};

export const buyFeatureClickTextStyle = buyFeatureHoverTextStyle;

export const buyFeatureDisableTextStyle: Partial<AutoResizeTextStyle> = {
  ...buyFeatureTextStyle,
  fill: '#474747',
  strokeThickness: 2,
  dropShadow: false,
};
